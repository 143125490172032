import * as React from "react"
import { View } from "react-native"
import { Button, Menu, Divider, Provider } from "react-native-paper"
import MaterialCommunityIcons from "@expo/vector-icons/MaterialCommunityIcons"
import { TouchableOpacity } from "react-native-gesture-handler"
import { CreateSpace } from '@ui/MyAliensScreen/CreateSpace'
import { toggleCreateSpace } from "../../../slices/myAliensSlice"
import { useDispatch, useSelector } from "react-redux"

type SpaceType = {
  _id: string,
  name: string,
  created: string,
  refetchMySpaces: () => {}
}

export const SelectSpace = ({ spaces, changeActiveSpace, activeSpace, refetchMySpaces }: any): JSX.Element => {
  const [visible, setVisible] = React.useState(false)
  const openMenu = () => setVisible(true)
  const closeMenu = () => setVisible(false)
  const dispatch = useDispatch()

  const onSpaceClick = (space: SpaceType) => {
    closeMenu()
    changeActiveSpace(space)
  }

  const onCreateSpaceClick = async () => {
    closeMenu()
    // dirty bugfix for when first input gets unfocused because of Menu fadeout & dispatch race condition
    setTimeout(() => dispatch(toggleCreateSpace(true)), 300) 
  }

  return (
    <View style={{ marginBottom: -20, marginTop: 15 }}>
      <Menu
        visible={visible}
        onDismiss={closeMenu}
        anchor={
          <Button
            icon={() => (
              <MaterialCommunityIcons
                style={{ color: "#ffffff" }}
                name="chevron-down"
                size={16}
              />
            )}
            mode="outlined"
            color="#ffffff"
            labelStyle={{ borderColor: "transparent" }}
            contentStyle={{ borderColor: "transparent" }}
            style={{
              marginBottom: 20,
              marginLeft: 10,
              elevation: 0,
              maxWidth: 270,
              backgroundColor: 'rgba(0, 0, 0, 0.35)',
              borderColor: "transparent",
              height: 40,
            }}
            onPress={openMenu}
          >
            {activeSpace ? activeSpace.name : 'NO SPACES'}
          </Button>
        }
      >
        {spaces.map((space: any, idx: number) => {
          return <Menu.Item onPress={() => onSpaceClick(space)} title={space.name} key={idx} />
        })}
        <Divider />
        <Menu.Item onPress={onCreateSpaceClick} title="New space" />
        {/* <CreateSpace changeActiveSpace={changeActiveSpace} closeParent={closeMenu} anchor={
          <Menu.Item onPress={() => { }} title="New space" />
        } /> */}
      </Menu>
    </View>
  );
};
