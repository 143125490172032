import * as React from "react"
import { View } from "react-native";
import { Button, Portal, Dialog, TextInput } from "react-native-paper"
import { gql, useMutation } from "@apollo/client";
import { TouchableOpacity } from "react-native-gesture-handler"

const CREATE_FEEDER_MUTATION = gql`
  mutation Mutation($createFeederRequest: CreateFeederRequest) {
    createFeeder(createFeederRequest: $createFeederRequest){
      _id,
      name,
      created,
      accesses {
          type
          userId
      },
      feeders {
          _id
          name
      }
  }
  }
`;

type CreateFeederType = {
  anchor: object
  spaceId: string
}

export const CreateFeeder = ({ anchor, spaceId }: CreateFeederType) => {
  const [visible, setVisible] = React.useState(false)
  const [createFeeder, { error }] = useMutation(CREATE_FEEDER_MUTATION);
  const openMenu = () => setVisible(true)
  const closeMenu = () => setVisible(false)
  const [name, setName] = React.useState('')

  const onPressSave = async () => {
    await createFeeder({
      variables: {
        createFeederRequest: {
          spaceId,
          name
        },
      },
    }).then(
      () => {
        setName('')
        closeMenu()
      }
    )
  }

  return (
    <View>
      <TouchableOpacity onPress={openMenu}>{anchor}</TouchableOpacity>
      <Portal>
        <Dialog
          style={{ width: '90%', maxWidth: 600, marginLeft: 'auto', marginRight: 'auto' }}
          visible={visible}
          onDismiss={closeMenu}>
          <Dialog.Title>New food source</Dialog.Title>
          <Dialog.Content>
            <TextInput
              autoFocus={true}
              label="Name"
              mode={"outlined"}
              value={name}
              multiline={false}
              numberOfLines={1}
              onChangeText={(text) => setName(text)} />
          </Dialog.Content>
          <Dialog.Actions style={{ padding: 24, paddingTop: 0 }}>
            <Button
              mode="outlined"
              style={{ width: 100, marginRight: 10 }}
              onPress={() => closeMenu()}>
              Cancel
            </Button>
            <Button
              mode="contained"
              style={{ width: 100 }}
              onPress={() => onPressSave()}>
              Save
            </Button>
          </Dialog.Actions>
        </Dialog>
      </Portal>
    </View>
  );
};
