import React, { useEffect } from "react"
import { View } from "react-native"
import { Headline, Title, Portal, Text, Badge, List, Button, Avatar, Menu } from "react-native-paper"
import { Modalize } from "react-native-modalize"
import { styles } from "./styles"
import { FeedAlien } from "@ui/MyAliensScreen/FeedAlien"
import { PastFeedingAlien } from "@ui/MyAliensScreen/PastFeedingAlien"
import { RejectedFoodAlien } from "@ui/MyAliensScreen/RejectedFoodAlien"
import { NoteAlien } from "@ui/MyAliensScreen/NoteAlien"
import { MoveAlien } from "@ui/MyAliensScreen/MoveAlien"
import { MoltAlien } from "@ui/MyAliensScreen/MoltAlien"
import { ArchiveAlien } from "@ui/MyAliensScreen/ArchiveAlien"
import { Dnd } from "@ui/MyAliensScreen/AlienDetails/Dnd"
import { DeleteFeeding } from "@ui/MyAliensScreen/AlienDetails/DeleteFeeding"
import { DeleteNote } from "@ui/MyAliensScreen/AlienDetails/DeleteNote"
import { DeleteMolt } from "@ui/MyAliensScreen/AlienDetails/DeleteMolt"
import { CancelDnd } from "@ui/MyAliensScreen/AlienDetails/CancelDnd"
import { Settings } from "@ui/MyAliensScreen/AlienDetails/Settings"
import MaterialCommunityIcons from "@expo/vector-icons/MaterialCommunityIcons"
import moment from 'moment'
import { TouchableOpacity } from "react-native-gesture-handler"
import { Dimensions } from 'react-native';
import { useDispatch } from "react-redux"
import { toggleNoteAlien, toggleDndAlien, toggleMoltAlien, toggleSettingsAlien } from "../../../slices/myAliensSlice"

const typeSnakeImg = require('../../../../assets/alien-type-snake.jpg')
const typeSpiderImg = require('../../../../assets/alien-type-spider.jpg')
const typeMantisImg = require('../../../../assets/alien-type-mantis.jpg')
const typeLizardImg = require('../../../../assets/alien-type-lizard.jpg')
const typeOtherImg = require('../../../../assets/alien-type-other.jpg')

type FeedingType = {
  _id: string
  type: string
  date: string
  name: string
}

type MoltType = {
  _id: string
  type: string
  date: string
  num: number
}

type NoteType = {
  _id: string
  type: string
  date: string
  text: string
}

type DndType = {
  date: string
  text: string
}

const { height, width } = Dimensions.get('window')

export const AlienDetails = React.forwardRef<any, any>(({ space, alien, mySpaces, refetchAliens, isSpaceAdmin }, ref: any) => {
  const [visibleMobileSidebar, setVisibleMobileSidebar] = React.useState(false)
  const openMobileSidebar = () => setVisibleMobileSidebar(true)
  const closeMobileSidebar = () => setVisibleMobileSidebar(false)
  const [screenWidth, setScreenWidth] = React.useState(width)
  const dispatch = useDispatch()

  useEffect(() => {
    const changeEventListener = Dimensions.addEventListener("change", () => {
      const { width } = Dimensions.get('window')
      setScreenWidth(width)
    })
    return () => {
      changeEventListener.remove()
    }
  }, [])

  const renderModal = () => {
    return (
      <Portal>
        <PastFeedingAlien feeders={space.feeders} isModal={true} />
        <NoteAlien />
        <Dnd />
        <MoltAlien />
        <Settings />
        <Modalize ref={ref} modalStyle={styles.modal}>
          {alien && renderHeader(alien.name, alien.species, alien.sex, alien.molts, alien.type)}
          {alien && renderContent(alien.feedings ? alien.feedings : [], alien.notes ? alien.notes : [], alien.molts ? alien.molts : [], alien.dnd)}
        </Modalize>
      </Portal>
    )
  }
  if (!alien) return renderModal();
  const alienId = alien._id
  const genderMale = (
    <MaterialCommunityIcons
      name="gender-male"
      style={styles.alienGender}
      size={16}
      color="lightblue"
    />
  );
  const genderFemale = (
    <MaterialCommunityIcons
      name="gender-female"
      style={styles.alienGender}
      size={16}
      color="pink"
    />
  );

  const getCurrentMoltNum = (molts: MoltType[]): Number => {
    if (!molts || molts.length === 0) return 0
    return molts[0].num
  }

  const renderHeader = (name: String, species: String, sex: String, molts: MoltType[], type: String) => {
    let alienGender: JSX.Element | undefined = undefined;
    if (sex === "male") alienGender = genderMale;
    if (sex === "female") alienGender = genderFemale;
    const moltNum = getCurrentMoltNum(molts)
    let alienImg
    if (typeof type === 'undefined' || type === null || !type) {
      alienImg = typeOtherImg
    } else if (type === 'snake') {
      alienImg = typeSnakeImg
    } else if (type === 'spider') {
      alienImg = typeSpiderImg
    } else if (type === 'mantis') {
      alienImg = typeMantisImg
    } else if (type === 'lizard') {
      alienImg = typeLizardImg
    } else if (type === 'lizard') {
      alienImg = typeLizardImg
    } else {
      alienImg = typeOtherImg
    }
    return (
      <View style={{ flexDirection: "row", marginBottom: 15, maxWidth: '100%' }}>
        <Avatar.Image
          style={styles.alienAvatar}
          size={60}
          source={{ uri: alienImg }}
        />
        <View style={{ paddingLeft: 15, flex: 9, maxWidth: '90%' }}>
          <Headline>{alien.name}</Headline>
          <Title style={{ fontSize: 16, color: "#727272" }}>
            {alienGender && alienGender}
            <Text style={styles.alienSpecies}>{moltNum > 0 ? 'L' + moltNum : ''} {species}</Text>
          </Title>
        </View>
        <TouchableOpacity onPress={() => ref.current.close()} style={{ flex: 2 }}>
          <MaterialCommunityIcons
            style={{ color: '#ccc' }}
            name="close-circle"
            size={32}
          />
        </TouchableOpacity>
      </View>
    )
  }

  const renderNoActivity = () => {
    return (
      <View style={styles.infoNoteSurface}>
        <MaterialCommunityIcons
          style={{ color: "white" }}
          name="help-circle-outline"
          size={16}
        />
        <Text style={styles.warningNoteText}>You've not tracked any activity for this alien yet.</Text>
      </View>
    )
  }

  const renderStickyNotes = (notes: NoteType[]) => {
    return (
      notes.map((note, idx) => {
        return (<View style={styles.warningNoteSurface} key={idx}>
          {/* <View style={{flex:3}}> */}
          {/* <View> */}
          <Badge style={[styles.badge, styles.badgeWhite]}>{moment(note.date).fromNow()}</Badge>
          {/* </View> */}
          <Text style={styles.warningNoteText}>
            <MaterialCommunityIcons
              style={{ color: "white", marginRight: 5 }}
              name="alert"
              size={16}
            />
            {note.text}
          </Text>
          <DeleteNote alienId={alien._id} noteId={note._id} anchor={
            <MaterialCommunityIcons
              style={{ color: "white", marginTop: 0 }}
              name="close-circle"
              size={16}
            />
          } />
        </View>)
      })
    )
  }

  const renderDnd = (dnd: DndType) => {
    const text = dnd.text ? dnd.text : 'Do not disturb.'
    return (
      <View style={styles.warningNoteSurface}>
        <Badge style={[styles.badge, styles.badgeWhite]}>{moment(dnd.date).fromNow()}</Badge>
        <Text style={styles.warningNoteText}>
          <MaterialCommunityIcons
            style={{ color: "white", marginRight: 5 }}
            name="hand-back-left-off"
            size={16}
          />
          {text}
        </Text>
        <CancelDnd alienId={alien._id} anchor={
          <MaterialCommunityIcons
            style={{ color: "white", marginTop: 0 }}
            name="close-circle"
            size={16}
          />
        } />
      </View>
    )
  }

  const renderActivities = (feedings: FeedingType[] = [], notes: NoteType[] = [], molts: MoltType[] = []): JSX.Element => {
    let activities: any = [...feedings, ...notes, ...molts]
    activities.sort((a:any, b:any) => (a.date < b.date) ? 1 : -1)
    return activities.map((activity: any, idx: any) => {
      const listItemClass = (idx + 1) === activities.length ? styles.activityListItemLast : styles.activityListItem
      let title
      if (activity.__typename === 'Feeding') {
        const badgeColorStyle = activity.type === 'ok' ? styles.badgeGreen : styles.badgeOrange
        const iconColorStyle = activity.type === 'ok' ? styles.iconGreen : styles.iconOrange
        return (
          <List.Item
            key={idx}
            title={activity.name}
            style={listItemClass}
            description={moment(activity.date).format('MMMM Do YYYY')}
            descriptionNumberOfLines={3}
            titleNumberOfLines={0}
            titleStyle={{ marginBottom: 3 }}
            left={(props) => (
              <View style={{ alignItems: 'center', paddingTop: 4, maxWidth: 70 }}>
                <List.Icon  {...props} color={'white'} style={{ margin: 0, backgroundColor: iconColorStyle.color, borderRadius: 50 }} icon="bug" />
                <Badge style={[styles.badge, badgeColorStyle]}>
                  {moment(activity.date).fromNow()}
                </Badge>
              </View>
            )}
            right={(props) => (
              <DeleteFeeding alienId={alien._id} feedingId={activity._id} anchor={
                <MaterialCommunityIcons
                  style={{ color: "gray", marginTop: 22 }}
                  name="close-circle"
                  size={16}
                />
              } />
            )}
          />
        )
      } else if (activity.__typename === 'Molt') {
        let moltString = 'Molted from L' + (activity.num - 1) + ' to L' + activity.num
        if (activity.type === 'adult') {
          moltString += ' (adult)'
        }
        return (
          <List.Item
            key={idx}
            title={moltString}
            style={listItemClass}
            description={moment(activity.date).format('MMMM Do YYYY')}
            descriptionNumberOfLines={1}
            titleNumberOfLines={0}
            left={(props) => (
              <View style={{ alignItems: 'center', paddingTop: 4, maxWidth: 70 }}>
                <List.Icon  {...props} color={'white'} style={{ margin: 0, backgroundColor: 'purple', borderRadius: 50 }} icon="autorenew" />
                <Badge style={[styles.badge, styles.badgePurple]}>
                  {moment(activity.date).fromNow()}
                </Badge>
              </View>
            )}
            right={(props) => (
              <DeleteMolt alienId={alien._id} moltId={activity._id} anchor={
                <MaterialCommunityIcons
                  style={{ color: "gray", marginTop: 22 }}
                  name="close-circle"
                  size={16}
                />
              } />
            )}
          />
        )
      } else if (activity.__typename === 'Note') {
        return (
          <List.Item
            key={idx}
            title={activity.text}
            style={listItemClass}
            // description={}
            description={moment(activity.date).format('MMMM Do YYYY')}
            descriptionNumberOfLines={3}
            titleNumberOfLines={0}
            // titleStyle={{ height: 0 }}
            titleStyle={{ marginBottom: 3 }}
            left={(props) => (
              <View style={{ alignItems: 'center', paddingTop: 4, maxWidth: 70 }}>
                <List.Icon  {...props} color={'white'} style={{ margin: 0, backgroundColor: styles.iconBlue.color, borderRadius: 50 }} icon="comment-text-outline" />
                <Badge style={[styles.badge, styles.badgeBlue]}>
                  {moment(activity.date).fromNow()}
                </Badge>
              </View>
            )}
            right={(props) => (
              <DeleteNote alienId={alien._id} noteId={activity._id} anchor={
                <MaterialCommunityIcons
                  style={{ color: "gray", marginTop: 22 }}
                  name="close-circle"
                  size={16}
                />
              } />
            )}
          />
        )
      }
    })
  }

  const renderContent = (feedings: FeedingType[] = [], notes: NoteType[] = [], molts: MoltType[] = [], dnd: DndType | null) => {
    let normalNotes = []
    let stickyNotes = []
    if (notes) {
      for (let i = 0; i < notes.length; i++) {
        if (notes[i].type === 'normal') {
          normalNotes.push(notes[i])
        } else if (notes[i].type === 'sticky') {
          stickyNotes.push(notes[i])
        }
      }
    }
    if (screenWidth < 600) {
      return (
        <View style={{ display: "flex", flexDirection: "column" }}>
          {screenWidth < 600 && <View style={{ width: '100%' }}>{renderMobileSidebar()}</View>}
          <View>
            {
              (typeof feedings == 'undefined' || feedings == null || feedings.length == 0) &&
                (typeof notes == 'undefined' || notes == null || notes.length == 0) ? renderNoActivity() : null
            }
            {dnd && renderDnd(dnd)}
            {stickyNotes && stickyNotes.length > 0 && renderStickyNotes(stickyNotes)}
            <List.Section style={{ marginBottom: 30 }}>
              {renderActivities(feedings, normalNotes, molts)}
            </List.Section>
          </View>
        </View>
      )
    } else {
      return (
        <View style={{ display: "flex", flexDirection: "row" }}>
          {screenWidth < 600 && <View style={{ width: '100%' }}>{renderMobileSidebar()}</View>}
          <View style={{ flex: 3, paddingRight: 15 }}>
            {
              (typeof feedings == 'undefined' || feedings == null || feedings.length == 0) &&
                (typeof notes == 'undefined' || notes == null || notes.length == 0) ? renderNoActivity() : null
            }
            {dnd && renderDnd(dnd)}
            {stickyNotes && stickyNotes.length > 0 && renderStickyNotes(stickyNotes)}
            {renderActivities(feedings, normalNotes, molts)}
          </View>
          {screenWidth > 600 && renderSidebar()}
        </View>
      )
    }
  }

  const onMoltAlienClick = (previousMolt: { open: boolean, previousMolt: object } | null) => {
    closeMobileSidebar()
    dispatch(toggleMoltAlien({ open: true, previousMolt }))
  }

  const renderMobileSidebar = () => {
    const previousMolt = alien.molts && alien.molts.length > 0 ? alien.molts[0] : null
    const archiveBtnMargin = isSpaceAdmin ? 0 : 30
    const onNoteAlienClick = () => {
      closeMobileSidebar()
      // dirty bugfix for when first input gets unfocused because of Menu fadeout & dispatch race condition
      setTimeout(() => dispatch(toggleNoteAlien(true)), 300)
    }

    const onDndAlienClick = () => {
      closeMobileSidebar()
      setTimeout(() => dispatch(toggleDndAlien(true)), 300)
    }

    const onSettingsAlienClick = (name: string, species: string, gender: string) => {
      closeMobileSidebar()
      setTimeout(() => dispatch(toggleSettingsAlien({ open: true, name, species, gender })), 300)
    }

    return (
      <Menu
        contentStyle={{ padding: 10 }}
        visible={visibleMobileSidebar}
        onDismiss={closeMobileSidebar}
        anchor={
          <Button
            onPress={openMobileSidebar}
            icon="food-drumstick"
            mode="contained"
            style={{ marginBottom: 10 }}>
            Actions
          </Button>}>
        <View style={{ flex: 1 }}>
          <FeedAlien
            isModal={true}
            closeParent={closeMobileSidebar}
            key={'feed' + alienId}
            alienId={alienId}
            feeders={space.feeders}
            anchor={
              <Button
                icon="food-drumstick"
                mode="contained"
                style={{ marginBottom: 10 }}>
                Feed
              </Button>
            }
          />
          <RejectedFoodAlien
            closeParent={closeMobileSidebar}
            key={'rejected' + alienId}
            alienId={alienId}
            feeders={space.feeders}
            anchor={
              <Button
                icon="food-drumstick-off"
                mode="contained"
                style={{ marginBottom: 10 }}>
                Rejected food
              </Button>
            }
          />
          <Button
            icon="comment-text"
            mode="contained"
            key={'note' + alienId}
            style={{ marginBottom: 10 }}
            onPress={() => onNoteAlienClick()}>
            Note
          </Button>
          <Button
            onPress={() => onMoltAlienClick(previousMolt)}
            key={'molt' + alienId}
            icon="refresh"
            mode="contained"
            style={{ marginBottom: 10 }}>
            Molt
          </Button>
          {/* <MoltAlien
            key={'molt' + alienId}
            alienId={alienId}
            previousMolt={previousMolt}
            anchor={
              <Button
                icon="refresh"
                mode="contained"
                style={{ marginBottom: 10 }}>
                Molt
              </Button>
            }
          /> */}
          {!alien.dnd && <Button
            key={'dnd' + alienId}
            onPress={() => onDndAlienClick()}
            icon="hand-back-left-off"
            mode="contained">
            Pre-Molt / DND
          </Button>}
          {isSpaceAdmin &&
            <MoveAlien
              key={'move' + alienId}
              alienId={alienId}
              mySpaces={mySpaces}
              refetchAliens={refetchAliens}
              closeModal={() => ref.current.close()}
              anchor={
                <Button
                  icon="arrow-right-bottom-bold"
                  mode="contained"
                  style={{ marginBottom: 10, marginTop: 30 }}>
                  Move
                </Button>
              }
            />
          }
          <ArchiveAlien
            key={'archive' + alienId}
            alienId={alienId}
            action={'archive'}
            anchor={
              <Button
                icon="archive"
                mode="contained"
                style={{ marginTop: archiveBtnMargin, marginBottom: 10 }}>
                Archive
              </Button>
            }
          />
          {/* <Settings
            key={'settings' + alienId}
            alienId={alienId}
            alienName={alien.name}
            alienSpecies={alien.species}
            alienSex={alien.sex} */}
          <Button
            onPress={() => onSettingsAlienClick(alien.name, alien.species, alien.sex)}
            key={'settings' + alienId}
            icon="lead-pencil"
            mode="contained">
            Settings
          </Button>
        </View>
      </Menu>
    )
  }

  const renderSidebar = () => {
    const previousMolt = alien.molts && alien.molts.length > 0 ? alien.molts[0] : null
    const archiveBtnMargin = isSpaceAdmin ? 0 : 30
    return (
      <View style={{ flex: 1 }}>
        <FeedAlien
          isModal={true}
          key={'feed' + alienId}
          alienId={alienId}
          feeders={space.feeders}
          anchor={
            <Button
              icon="food-drumstick"
              mode="contained"
              style={{ marginBottom: 10 }}>
              Feed
            </Button>
          }
        />
        <RejectedFoodAlien
          key={'rejected' + alienId}
          alienId={alienId}
          feeders={space.feeders}
          anchor={
            <Button
              icon="food-drumstick-off"
              mode="contained"
              style={{ marginBottom: 10 }}>
              Rejected food
            </Button>
          }
        />
        <Button
          icon="comment-text"
          mode="contained"
          key={'note' + alienId}
          style={{ marginBottom: 10 }}
          onPress={() => dispatch(toggleNoteAlien(true))}>
          Note
        </Button>
        <Button
          onPress={() => onMoltAlienClick(previousMolt)}
          key={'molt' + alienId}
          icon="refresh"
          mode="contained"
          style={{ marginBottom: 10 }}>
          Molt
        </Button>
        {!alien.dnd &&
          <Button
            onPress={() => dispatch(toggleDndAlien(true))}
            key={'dnd' + alienId}
            icon="hand-back-left-off"
            mode="contained">
            Pre-Molt / DND
          </Button>
        }
        {isSpaceAdmin &&
          <MoveAlien
            key={'move' + alienId}
            alienId={alienId}
            mySpaces={mySpaces}
            refetchAliens={refetchAliens}
            closeModal={() => ref.current.close()}
            anchor={
              <Button
                icon="arrow-right-bottom-bold"
                mode="contained"
                style={{ marginBottom: 10, marginTop: 30 }}>
                Move
              </Button>
            }
          />
        }
        <ArchiveAlien
          key={'archive' + alienId}
          alienId={alienId}
          action={'archive'}
          anchor={
            <Button
              icon="archive"
              mode="contained"
              style={{ marginTop: archiveBtnMargin, marginBottom: 10 }}>
              Archive
            </Button>
          }
        />
        <Button
          onPress={() => dispatch(toggleSettingsAlien({ open: true, name: alien.name, species: alien.species, gender: alien.sex }))}
          key={'settings' + alienId}
          icon="lead-pencil"
          mode="contained">
          Settings
        </Button>
      </View>
    )
  }
  return (renderModal())
})
