import React from "react";
import { SafeAreaView } from 'react-native';
import StyleSheet from "react-native-media-query";
import {theme} from '@ui/theme'

const {ids, styles} = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: theme.colors.background
  },
});

export default (props) => {
  return (<SafeAreaView style={styles.container} {...props} />)
}