import React from 'react'
import { Button, Dialog, Portal, TextInput } from "react-native-paper"
import { gql, useMutation } from "@apollo/client"
import { toggleCreateSpace } from "../../../slices/myAliensSlice"
import { useDispatch, useSelector } from "react-redux"

const CREATE_SPACE_MUTATION = gql`
  mutation Mutation($spaceData: SpaceData) {
    createSpace(spaceData: $spaceData) {
        _id
        name
        created
        accesses {
            type
            userId
            username
        }
        feeders {
            _id
            name
        }
    }
  }
`

type CreateSpaceType = {
    changeActiveSpace: (space: any) => void
    refetchMySpaces: () => void
}

export const CreateSpace = ({ changeActiveSpace, refetchMySpaces }: CreateSpaceType) => {
    const defaultName = 'My exotic farm'
    const [createSpace, { error }] = useMutation(CREATE_SPACE_MUTATION)
    const [spaceName, setSpaceName] = React.useState(defaultName)
    const { isCreateSpaceOpen }: any = useSelector(state => state)
    const dispatch = useDispatch()
    const closeMenu = () => dispatch(toggleCreateSpace(false))
    const onPressSave = async () => {
        await createSpace({
            variables: {
                spaceData: {
                    name: spaceName
                },
            },
        }).then((response) => {
            const space = response.data.createSpace
            setSpaceName(defaultName)
            closeMenu()
            changeActiveSpace(space)
            refetchMySpaces()
        }).catch(
            // () => console.log('errorrrr')
        )
    }
    return (
        <Portal>
            <Dialog
                style={{ width: '90%', maxWidth: 600, marginLeft: 'auto', marginRight: 'auto' }}
                visible={isCreateSpaceOpen}
                onDismiss={closeMenu}>
                <Dialog.Title>New space</Dialog.Title>
                <Dialog.Content>
                    <TextInput
                        autoFocus={true}
                        label="Space name"
                        mode={"outlined"}
                        value={spaceName}
                        multiline={false}
                        numberOfLines={1}
                        onChangeText={(text) => setSpaceName(text)}
                    />
                </Dialog.Content>
                <Dialog.Actions style={{ padding: 24, paddingTop: 0 }}>
                    <Button
                        mode="outlined"
                        style={{ marginRight: 10, marginBottom: 0, maxWidth: 120 }}
                        onPress={() => closeMenu()}>
                        Cancel
                    </Button>
                    <Button
                        mode="contained"
                        style={{ maxWidth: 120 }}
                        onPress={() => onPressSave()}>
                        Confirm
                    </Button>
                </Dialog.Actions>
            </Dialog>
        </Portal>
    )
}
