import React, { useEffect, FC, useState } from 'react'
import { gql, useMutation, useSubscription, useQuery, useLazyQuery } from "@apollo/client";
import { View, Text, TouchableOpacity, StyleSheet, ImageBackground, Dimensions } from "react-native";
import { ids, styles } from "./styles";
import { MyAlienCard } from '@ui/MyAliensScreen/MyAlienCard'
import { AlienActions } from '@ui/MyAliensScreen/MyAlienCard/AlienActions'
import MaterialCommunityIcons from "@expo/vector-icons/MaterialCommunityIcons";
// import { FlatList } from "react-native";
import MasonryList from '@react-native-seoul/masonry-list';
import { AlienDetails } from '@ui/MyAliensScreen/AlienDetails'
import { SelectSpace } from '@ui/MyAliensScreen/SelectSpace'
import { ListRenderItemInfo } from 'react-native'
import { RefObject } from 'react'
import { Card, Badge, List, TextInput, Button } from "react-native-paper";
import { UserContext, useUserContext } from '@ui/shared/UserContext'

const UPDATE_PASSWORD_MUTATION = gql`
  mutation Mutation($passwordData: PasswordData) {
    updatePassword(passwordData: $passwordData) {
        _id,
        username,
        email,
        created
    }
  }
`;

type ScreenType = {
    navigation?: any
}

const Password: FC<ScreenType> = ({ navigation }) => {
    const [updatePassword, { error }] = useMutation(UPDATE_PASSWORD_MUTATION);
    const [currentPassword, setCurrentPassword] = React.useState<string>('')
    const [newPassword, setNewPassword] = React.useState<string>('')
    const [repeatPassword, setRepeatPassword] = React.useState<string>('')
    const onPressSave = async () => {
        await updatePassword({
            variables: {
                passwordData: { currentPassword, newPassword, repeatPassword }
            }
        }).then(
            () => {
                setCurrentPassword('')
                setNewPassword('')
                setRepeatPassword('')
            }
        )
    }
    return (
        <Card style={styles.settingsCard} dataSet={{ media: ids.settingsCard }}>
            <View >
                {/* <Card.Cover
                            style={styles.alienAvatar}
                            source={{ uri: "https://picsum.photos/700" }}
                        /> */}
                <Card.Title
                    // style={styles.alienName}
                    title={'Change password'}
                    subtitle={'At least 6 characters required'}
                />
            </View>
            <View style={{ padding: 16, paddingTop: 0 }}>
                <TextInput
                    secureTextEntry={true}
                    label="Current password"
                    style={{ marginTop: 5, marginBottom: 5 }}
                    mode={"outlined"}
                    value={currentPassword}
                    multiline={false}
                    numberOfLines={1}
                    onChangeText={(text) => setCurrentPassword(text)}
                />
                <TextInput
                    secureTextEntry={true}
                    label="New password"
                    style={{ marginTop: 5, marginBottom: 5 }}
                    mode={"outlined"}
                    value={newPassword}
                    multiline={false}
                    numberOfLines={1}
                    onChangeText={(text) => setNewPassword(text)}
                />
                <TextInput
                    secureTextEntry={true}
                    label="Repeat new password"
                    style={{ marginTop: 10, marginBottom: 5 }}
                    mode={"outlined"}
                    value={repeatPassword}
                    multiline={false}
                    numberOfLines={1}
                    onChangeText={(text) => setRepeatPassword(text)}
                />
                {error && <Text style={{ color: 'red', marginLeft: 10, marginRight: 10, marginBottom: 10 }}>{error?.message}</Text>}
                <Button mode="contained" style={{ marginTop: 15, maxWidth: 200 }} onPress={() => onPressSave()} >Update</Button>
                <Text style={{ marginTop: 15, color: 'rgba(0, 0, 0, 0.54);' }}>Im case of a forgotten password you can always restore it using email.</Text>
            </View>
        </Card>
    )
}

export default Password