import * as React from "react"
import { Portal, Button, Dialog, TextInput, Checkbox } from "react-native-paper"
import { gql, useMutation } from "@apollo/client";
import moment from 'moment'
import { toggleNoteAlien } from "../../../slices/myAliensSlice"
import { useDispatch, useSelector } from "react-redux"

const NOTE_ALIEN_MUTATION = gql`
  mutation Mutation($noteRequest: NoteRequest) {
    noteAlien(noteRequest: $noteRequest) {
      _id
      name
    }
  }
`;

export const NoteAlien = () => {
  const [noteAlien, { error }] = useMutation(NOTE_ALIEN_MUTATION);
  const [text, setText] = React.useState('')
  const [checked, setChecked] = React.useState(false)
  const dispatch = useDispatch()
  const closeMenu = () => dispatch(toggleNoteAlien(false))
  const { isNoteAlienOpen, selectedAlienId: alienId }: any = useSelector(state => state)

  const onPressSave = async () => {
    const date = moment().toISOString()
    const type = !checked ? 'normal' : 'sticky'
    await noteAlien({
      variables: {
        noteRequest: {
          alienId,
          note: {
            type,
            date,
            text,
          }
        },
      },
    }).then(
      () => {
        setText('')
        setChecked(false)
        closeMenu()
      }
    )
  }
  return (
    <Portal>
      <Dialog
        style={{ width: '90%', maxWidth: 600, marginLeft: 'auto', marginRight: 'auto' }}
        visible={isNoteAlienOpen}
        onDismiss={closeMenu}>
        <Dialog.Title>Add note</Dialog.Title>
        <Dialog.Content>
          <TextInput
            autoFocus={true}
            label="Add note"
            style={{ marginTop: 0 }}
            mode={"outlined"}
            value={text}
            multiline={true}
            numberOfLines={4}
            onChangeText={(text) => setText(text)} />
          <Checkbox.Item label="Sticky note" status={checked ? "checked" : "unchecked"}
            onPress={() => {
              setChecked(!checked);
            }} />
        </Dialog.Content>
        <Dialog.Actions style={{ padding: 24, paddingTop: 0 }}>
          <Button
            mode="outlined"
            style={{ width: 100, marginRight: 10 }}
            onPress={() => closeMenu()}>
            Cancel
          </Button>
          <Button
            mode="contained"
            style={{ width: 100 }}
            onPress={() => onPressSave()}>
            Save
          </Button>
        </Dialog.Actions>
      </Dialog>
    </Portal>
  )
}
