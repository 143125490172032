import StyleSheet from "react-native-media-query";

export const { ids, styles } = StyleSheet.create({
  mainBackground: {
    // height: screenHeight,
    // width: screenWidth,
    padding: 0,
    margin: 0,
  },
  myAliensContainer: {},
  myAliensList: {
    margin: 0,
    padding: 0,
    paddingTop: 5,
    // marginBottom:30,
    // marginTop: 10,
    // marginBottom: 5,
  },
  myAlienCardWrapper: {
    // padding: 15,
    position: "relative",
    zIndex: 100,
    elevation: 101,
  },
  alienMoreOptsBtn: {
    zIndex: 4,
    elevation: 5,
    position: "absolute",
    // zIndex: 200,
    top: 17,
    right: 21,
    // bottom: 'auto',
    // width: '50px',
    // height: '50px',
  },
  settingsBtn: {
    marginTop: 15,
    marginRight: 10,
    elevation: 0,
    maxWidth: 100,
    backgroundColor: 'rgba(0, 0, 0, 0.35)',
    borderColor: "transparent",
    height: 40,
  },
  backToSpaceBtn: {
    height: 40,
    marginLeft: 5,
    marginTop: 15,
    marginRight: 10,
    elevation: 0,
    maxWidth: 200,
    backgroundColor: 'rgba(0, 0, 0, 0.35)',
    borderColor: "transparent",
},
});