import { createSlice } from '@reduxjs/toolkit'

const myAliensSlice = createSlice({
    name: "myAliens",
    initialState: {
        isCreateSpaceOpen: false,
        isNoteAlienOpen: false,
        isDndAlienOpen: false,
        isMoltAlienOpen: false,
        isSettingsAlienOpen: false,
        pastFeeding: {
            isOpen: false,
            alienId: null
        },
        pastFeedingModal: {
            isOpen: false,
            alienId: null
        },
        settingsAlien: {
            name: '',
            species: '',
            gender: '',
        },
        moltAlienPrevious: null, // previous alien molt containing "num" and "adult"
        selectedAlienId: null,
    },
    reducers: {
        toggleCreateSpace: (state, param) => {
            const { payload } = param
            state.isCreateSpaceOpen = payload
        },
        selectAlienId: (state, param) => {
            const { payload } = param
            state.selectedAlienId = payload
        },
        toggleNoteAlien: (state, param) => {
            const { payload } = param
            state.isNoteAlienOpen = payload
        },
        toggleDndAlien: (state, param) => {
            const { payload } = param
            state.isDndAlienOpen = payload
        },
        toggleMoltAlien: (state, param) => {
            const { payload } = param
            state.isMoltAlienOpen = payload.open
            state.moltAlienPrevious = payload.previousMolt
        },
        toggleSettingsAlien: (state, param) => {
            const { payload } = param
            state.isSettingsAlienOpen = payload.open
            state.settingsAlien = { name: payload.name, species: payload.species, gender: payload.gender }
        },
        togglePastFeedingAlien: (state, param) => {
            const { isOpen, alienId, location } = param.payload
            if (isOpen === true) {
                state.pastFeeding.isOpen = isOpen
                state.pastFeeding.alienId = alienId
            } else {
                state.pastFeeding.isOpen = false
                state.pastFeeding.alienId = null
            }
        },
        togglePastFeedingModalAlien: (state, param) => {
            const { isOpen, alienId } = param.payload
            if (isOpen === true) {
                state.pastFeedingModal.isOpen = isOpen
                state.pastFeedingModal.alienId = alienId
            } else {
                state.pastFeedingModal.isOpen = false
                state.pastFeedingModal.alienId = null
            }
        },
    }
})

const { actions, reducer } = myAliensSlice

export const {
    toggleCreateSpace,
    toggleNoteAlien,
    toggleDndAlien,
    toggleMoltAlien,
    toggleSettingsAlien,
    togglePastFeedingAlien,
    togglePastFeedingModalAlien,
    selectAlienId,
} = actions

export default reducer