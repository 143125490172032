import React, { useEffect, FC, useState } from 'react'
import { gql, useMutation, useSubscription, useQuery, useLazyQuery } from "@apollo/client";
import { View, Text, TouchableOpacity, StyleSheet, ImageBackground, Dimensions } from "react-native";
import SafeArea from "../../shared/SafeArea";
import { Surface, TextInput, Title, Button } from 'react-native-paper';

type ScreenType = {
    navigation?: any
    refetchUser: () => {}
}

const SIGN_UP_MUTATION = gql`
  mutation Mutation($username: String, $email: String, $password: String) {
    signUp(username: $username, email: $email, password: $password) {
        username,
        email
    }
  }
`;

const SignUpScreen: FC<ScreenType> = ({ navigation, refetchUser }) => {
    const [username, setUsername] = React.useState('')
    const [email, setEmail] = React.useState('')
    const [password, setPassword] = React.useState('')
    const [signUp, { error }] = useMutation(SIGN_UP_MUTATION);
    const resetInputs = () => {
        setUsername('')
        setEmail('')
        setPassword('')
    }

    const onPressSave = async () => {
        await signUp({
            variables: {
                username,
                email,
                password,
            },
        }).then(() => {
            resetInputs()
            refetchUser()
            // navigation.navigate('SignInScreen')
        })
    };

    return (
        <SafeArea>
            <ImageBackground
                source={{ uri: "https://picsum.photos/1024" }}
                resizeMode="cover"
                style={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}
            >
                <Surface style={{ minWidth: 400, maxWidth: 700, minHeight: 300, borderRadius: 8, padding: 20 }}>
                    <View style={{ display: "flex", flexDirection: "column" }}>
                        <Title style={{ marginTop: 15, color: "#727272" }}>
                            Greetings alienkeeper!
                        </Title>
                        <TextInput
                            label="Username"
                            style={{ marginTop: 0 }}
                            mode={"outlined"}
                            value={username}
                            multiline={false}
                            numberOfLines={1}
                            onChangeText={(text) => setUsername(text)}
                        />
                        <TextInput
                            label="Email"
                            style={{ marginTop: 10 }}
                            mode={"outlined"}
                            value={email}
                            multiline={false}
                            numberOfLines={1}
                            onChangeText={(text) => setEmail(text)}
                        />
                        <TextInput
                            secureTextEntry={true}
                            label="Password"
                            style={{ marginTop: 10 }}
                            mode={"outlined"}
                            value={password}
                            multiline={false}
                            numberOfLines={1}
                            onChangeText={(text) => setPassword(text)}
                        />
                    </View>
                    {error && <Text style={{ color: 'red', marginLeft: 10, marginRight: 10, marginBottom: 10 }}>{error?.message}</Text>}
                    <Button
                        mode="contained"
                        style={{ marginTop: 15, marginBottom: 0, marginLeft: 'auto', marginRight: 'auto', minWidth: 150, maxWidth: 120 }}
                        onPress={() => onPressSave()}>Continue</Button>
                    <View style={{ flex: 1, flexDirection: 'row', marginTop: 15 }}>
                        <TouchableOpacity onPress={() => navigation.navigate('SignInScreen')} style={{ marginTop: 10, flex: 1 }}>
                            <Text style={{ color: '#767676' }}>Already registered?</Text>
                        </TouchableOpacity>
                    </View>
                </Surface>
            </ImageBackground>
        </SafeArea >
    )
}

export default SignUpScreen
