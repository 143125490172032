import React, { FC, useState, useRef, RefObject } from 'react'
import { gql, useSubscription, useQuery } from "@apollo/client";
import { View, TouchableOpacity, Text } from "react-native"
import { ids, styles } from "@ui/MyAliensScreen/SpaceAliens/styles"
import MasonryList from '@react-native-seoul/masonry-list'
import { MyAlienCard } from '@ui/MyAliensScreen/MyAlienCard'
import { AlienActions } from '@ui/MyAliensScreen/MyAlienCard/AlienActions'
import { Modalize } from "react-native-modalize"
import { AlienDetails } from '@ui/MyAliensScreen/AlienDetails'
import { useUserContext } from '@ui/shared/UserContext'
import moment from 'moment'
import { selectAlienId } from "../../../slices/myAliensSlice"
import { useDispatch, useSelector } from "react-redux"
import { NoteAlien } from '@ui/MyAliensScreen/NoteAlien'
import SpaceSettings from '@ui/MyAliensScreen/SpaceSettings'
import { ScrollView } from 'react-native-gesture-handler';

type ScreenType = {
    navigation?: any
    space: SpaceType
    gridColumns: number
    mySpaces: SpaceType[]
    refetchMySpaces: () => {}
    header: any
    isShowSettings: boolean
    changeActiveSpace: (space: SpaceType | null) => void
    setShowSettings: (show: boolean) => void
}

type SpaceType = {
    _id: string,
    name: string,
    created: string,
    accesses: AccessType[]
    feeders: FeederType[]
}

type FeederType = {
    _id: string
    name: string
}

type AccessType = {
    userId: string,
    type: string,
}

const GET_ALIENS_QUERY = gql`
query getAliens($spaceId:String!) {
    getAliens(spaceId: $spaceId) {
      _id
      name
      species
      sex
      feedings {
        _id
        type
        name
        date
      }
      notes {
        _id
        type
        text
        date
      }
      molts {
        _id
        type
        date
        num
      }
      dnd {
          date
          text
      }
      type
  }
}`


const ALIEN_UPDATED_SUBSCRIPTION = gql`
subscription alienUpdated($spaceId: ID!) {
    alienUpdated(spaceId: $spaceId) {
        type
        alien { 
            _id
            name
            species
            sex
            feedings {
                _id
                type
                name
                date
            }
            notes {
                _id
                type
                text
                date
            }
            molts {
                _id
                type
                date
                num
            }
            archived {
                date
            }
            dnd {
                date
                text
            }
            type
        }
    }
}`;

const SPACE_UPDATED_SUBSCRIPTION = gql`
subscription spaceUpdated {
    spaceUpdated {
      type
      space {
        _id
        name
        created
        accesses {
            username
            userId
            type
        },
        feeders {
            _id
            name
        }
      }
    }
}`;

type AlienType = {
    _id: string
    name: string
    species: string
    sex: string
    feedings: FeedingType[]
    dnd: object | undefined | null
}

type FeedingType = {
    _id: string
    type: string
    date: string
    name: string
}

export const SpaceAliens: FC<ScreenType> = ({ navigation, space, gridColumns, mySpaces, refetchMySpaces, header, isShowSettings, changeActiveSpace, setShowSettings }) => {
    const { user: currentUser } = useUserContext()
    const [myAliens, setMyAliens] = useState<AlienType[]>([])
    const AlienDetailsModalRef: RefObject<any> = useRef<Modalize>(null)
    const { selectedAlienId: viewAlienDetailsId }: any = useSelector(state => state)
    const dispatch = useDispatch()
    const setViewAlienDetailsId = (alienId: string | null) => dispatch(selectAlienId(alienId))

    const { loading: getAliensLoading, error: getAliensError, data: myAliensData, refetch: refetchGetAliens } = useQuery(GET_ALIENS_QUERY, {
        fetchPolicy: 'network-only',
        variables: { spaceId: space._id },
        onCompleted: (response) => {
            const aliens = sortAliensByHunger(response.getAliens)
            setMyAliens([...aliens])
        }
    })

    const getHunger = (alien: AlienType): number => {
        const notEnoughFeedingsErrorMsg = 'At least 2 successful feedings are required to track hunger.'
        if (typeof alien.feedings === 'undefined') throw new Error(notEnoughFeedingsErrorMsg)
        if (alien.dnd) throw new Error('alien is in premolt')
        const feedings = alien.feedings
        let latestFeedings = []
        let lastFed: any = null
        for (let i = 0; i < feedings.length; i++) {
            if (feedings[i].type === 'ok') {
                latestFeedings.push(feedings[i])
                if (lastFed === null) lastFed = feedings[i]
            }
            if (latestFeedings.length === 7) break // takes into consideration last 2 up to 7 successful feedings.
        }
        if (latestFeedings.length < 2 || !lastFed) throw new Error(notEnoughFeedingsErrorMsg)
        let feedingIntervals = []
        latestFeedings.reverse()
        let sumOfAllIntervals = 0
        for (let y = 0; y < latestFeedings.length; y++) {
            let currentFeeding = moment(latestFeedings[y].date)
            if (typeof latestFeedings[y + 1] === 'undefined') break
            let nextFeeding = moment(latestFeedings[y + 1].date)
            const daysBetweenFeedings = nextFeeding.diff(currentFeeding, 'days')
            sumOfAllIntervals += daysBetweenFeedings
            feedingIntervals.push(daysBetweenFeedings)
        }
        const averageFeedingFrequency = Math.ceil(sumOfAllIntervals / feedingIntervals.length)
        const now = moment()
        const fedDaysAgo = now.diff(lastFed.date, "days")
        if (averageFeedingFrequency === 0) throw new Error('Intradaily hunger tracking not supported.') // we do not track intradaily hunger
        const averageAndLastFedDiff = averageFeedingFrequency - fedDaysAgo
        const hunger = 100 - ((averageAndLastFedDiff / fedDaysAgo) * 100)
        return hunger
    }

    const sortAliensByHunger = (aliens: AlienType[]): AlienType[] => {
        let aliensWithoutHunger = []
        let aliensWithHunger = []
        for (let i = 0; i < aliens.length; i++) {
            let alien: any
            try {
                alien = { hunger: getHunger(aliens[i]), ...aliens[i] }
                aliensWithHunger.push(alien)
            } catch (error: any) {
                aliensWithoutHunger.push(aliens[i])
            }
        }
        aliensWithHunger.sort((a, b) => (a.hunger < b.hunger) ? 1 : -1)
        return aliensWithHunger.concat(aliensWithoutHunger)
    }

    //TODO: Subscription not working for user when he is located on settings page? :-D because component is not mounted
    const { data: spaceUpdatedEvent, loading: spaceUpdatedLoading, error: spaceUpdatedError } = useSubscription(SPACE_UPDATED_SUBSCRIPTION, {
        fetchPolicy: 'network-only',
        onSubscriptionData: ({ subscriptionData }) => {
            refetchMySpaces()
        },
    })

    const { data: alienUpdatedEvent, loading: alienUpdatedLoading, error: alienUpdatedError } = useSubscription(ALIEN_UPDATED_SUBSCRIPTION, {
        variables: { spaceId: space._id },
        fetchPolicy: 'network-only',
        onSubscriptionData: ({ subscriptionData }) => {
            if (typeof subscriptionData.data.alienUpdated !== 'undefined' && subscriptionData.data.alienUpdated.type === 'archive') {
                const { alien } = subscriptionData.data.alienUpdated
                if (selectedAlien && selectedAlien._id === alien._id) {
                    closeAlienDetails()
                }
            }
            refetchGetAliens()
        }
    })

    const openAlienDetails = (alienId: string) => {
        setViewAlienDetailsId(alienId)
        AlienDetailsModalRef.current?.open()
    }

    const closeAlienDetails = () => {
        setViewAlienDetailsId(null)
        AlienDetailsModalRef.current?.close()
    }

    const isAdminOfSpace = (space: SpaceType): boolean => {
        for (let i = 0; i < space.accesses.length; i++) {
            if (space.accesses[i].userId === currentUser?._id && space.accesses[i].type === 'admin') return true
        }
        return false
    }

    const renderMyAlienCard = ({ item: alien, i }: { item: any, i: any }) => {
        const feedings = alien.feedings ? alien.feedings : []
        const notes = alien.notes ? alien.notes : []
        const molts = alien.molts ? alien.molts : []
        let activities: any = [...feedings, ...notes, ...molts]
        activities.sort((a: any, b: any) => (a.date < b.date) ? 1 : -1)
        return (
            <View dataSet={{ media: ids.myAlienCardWrapper }}>
                <TouchableOpacity onPress={() => openAlienDetails(alien._id)}>
                    <MyAlienCard
                        name={alien.name}
                        species={alien.species}
                        sex={alien.sex}
                        feedings={alien.feedings}
                        molts={alien.molts}
                        notes={alien.notes}
                        dnd={alien.dnd}
                        type={alien.type}
                        hunger={alien.hunger}
                        activities={activities}
                    />
                </TouchableOpacity>
                <View style={styles.alienMoreOptsBtn}>
                    <AlienActions alienId={alien._id} feeders={space.feeders} />
                </View>
            </View>
        )
    }
    const isSpaceAdmin = isAdminOfSpace(space)
    let selectedAlien: any
    if (viewAlienDetailsId) {
        for (let i = 0; i < myAliens.length; i++) {
            if (myAliens[i]._id == viewAlienDetailsId) {
                selectedAlien = myAliens[i]
            }
        }
    }

    if (isShowSettings) {
        return (
            <ScrollView>
                {header()}
                <SpaceSettings space={space} setShowSettings={setShowSettings} changeActiveSpace={changeActiveSpace} />
            </ScrollView>

        )
    }

    return (
        <View style={{ flex: 1 }}>
            <AlienDetails
                ref={AlienDetailsModalRef}
                space={space}
                alien={selectedAlien}
                mySpaces={mySpaces}
                refetchAliens={refetchGetAliens}
                isSpaceAdmin={isSpaceAdmin} />
            <MasonryList
                ListHeaderComponent={header()}
                key={gridColumns}
                showsHorizontalScrollIndicator={false}
                showsVerticalScrollIndicator={false}
                style={styles.myAliensList}
                dataSet={{ media: ids.myAliensList }}
                numColumns={gridColumns}
                data={myAliens}
                keyExtractor={(alien) => alien._id}
                renderItem={(rowData) => renderMyAlienCard(rowData)}
            />
        </View>
    )
}
