// import { ids, styles } from "./styles";
import React, { useEffect, FC, useState } from 'react'
import { View } from "react-native";
import { Menu, Divider, Provider } from "react-native-paper";
import MaterialCommunityIcons from "@expo/vector-icons/MaterialCommunityIcons";
import { TouchableOpacity } from "react-native-gesture-handler";
import { gql, useMutation, useSubscription, useQuery } from "@apollo/client";
import moment from 'moment'
import { Surface, TextInput, Title, Button, Text } from 'react-native-paper'
// import SafeArea from "../../shared/SafeArea"

const UPDATE_SPACE_MUTATION = gql`
  mutation Mutation($updateData: UpdateData) {
    updateSpace(updateData: $updateData) {
        _id,
        name
    }
  }
`;

export const RenameSpace = ({ spaceId, spaceName }: any): JSX.Element => {
    const [name, setSpaceName] = React.useState(spaceName)
    const [updateSpace, { error }] = useMutation(UPDATE_SPACE_MUTATION)

    const onPressSave = async () => {
        await updateSpace({
            variables: {
                updateData: { spaceId, name },
            },
        })
    }

    useEffect(() => {
        setSpaceName(spaceName)
    }, [spaceName])

    return (
        <View>
            <TextInput
                label="Space name"
                style={{ marginTop: 10, marginBottom: 5 }}
                mode={"outlined"}
                value={name}
                multiline={false}
                numberOfLines={1}
                onChangeText={(text) => setSpaceName(text)}
            />
            <Button
                mode="contained"
                style={{ marginTop: 15, marginBottom: 15, minWidth: 150, maxWidth: 120 }}
                onPress={() => onPressSave()}>Update</Button>
        </View>
    )
}