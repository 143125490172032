// import { ids, styles } from "./styles"
import * as React from "react"
import { View, Text } from "react-native";
import { Button, Menu, Divider, Provider, TextInput, Checkbox, Title } from "react-native-paper"
import MaterialCommunityIcons from "@expo/vector-icons/MaterialCommunityIcons"
import { gql, useMutation, useSubscription, useQuery } from "@apollo/client";
import { TouchableOpacity } from "react-native-gesture-handler"
import moment from 'moment'

const DELETE_FEEDER_MUTATION = gql`
  mutation Mutation($deleteFeederRequest: DeleteFeederRequest) {
    deleteFeeder(deleteFeederRequest: $deleteFeederRequest){
        _id,
        name,
        created,
        accesses {
            type
            userId
        },
        feeders {
            _id
            name
        }
    }
  }
`;

type DeleteFeederType = {
    anchor: object
    spaceId: string
    feeder: FeederType
    //   alienId: string
}

type FeederType = {
    _id: string,
    name: string,
}

export const DeleteFeeder = ({ anchor, spaceId, feeder }: DeleteFeederType) => {
    const [visible, setVisible] = React.useState(false)
    const [deleteFeeder, { error }] = useMutation(DELETE_FEEDER_MUTATION);
    const openMenu = () => setVisible(true)
    const closeMenu = () => setVisible(false)

    const onPressSave = async () => {
        await deleteFeeder({
            variables: {
                deleteFeederRequest: {
                    spaceId,
                    feederId: feeder._id
                },
            },
        })
            .then(
                () => {
                    closeMenu()
                }
            ).catch((error) => {
                // just catch
            })
    }

    return (
        <View>
            <Menu
                visible={visible}
                onDismiss={closeMenu}
                anchor={<TouchableOpacity onPress={openMenu}>{anchor}</TouchableOpacity>}
            >
                <View>
                    <Title style={{ paddingLeft: 10, paddingRight: 10, paddingBottom: 10 }}>Are you sure?</Title>
                    <Text style={{ paddingLeft: 10, paddingRight: 10, paddingBottom: 10 }}>"{feeder.name}" will be removed.</Text>
                    {error && <Text style={{ color: 'red', marginLeft: 10, marginRight: 10, marginBottom: 10 }}>{error?.message}</Text>}
                    <Button
                        mode="contained"
                        style={{ margin: 10, marginBottom: 0, marginTop: 10, maxWidth: 120 }}
                        onPress={() => onPressSave()}
                    >
                        Confirm
                    </Button>
                </View>
            </Menu>
        </View>
    );
};
