import * as React from "react"
import { View } from "react-native";
import { Button, Menu } from "react-native-paper"
import { gql, useMutation } from "@apollo/client";
import { TouchableOpacity } from "react-native-gesture-handler"
import moment from 'moment'

const ARCHIVE_ALIEN_GQL = gql`
  mutation Mutation($archiveRequest: ArchiveRequest) {
    archiveAlien(archiveRequest: $archiveRequest) {
      _id
    }
  }
`;

type ArchiveAlienType = {
  anchor: object
  alienId: string
  action: string
}

export const ArchiveAlien = ({ anchor, alienId, action }: ArchiveAlienType) => {
  const [visible, setVisible] = React.useState(false)
  const [archiveAlien, { error }] = useMutation(ARCHIVE_ALIEN_GQL);
  const openMenu = () => setVisible(true)
  const closeMenu = () => setVisible(false)

  const onPressSave = async () => {
    const date = moment().toISOString()
    await archiveAlien({
      variables: {
        archiveRequest: {
          alienId,
          date,
          action
        },
      },
    })
  }

  return (
    <View>
      <Menu
        visible={visible}
        onDismiss={closeMenu}
        anchor={<TouchableOpacity onPress={openMenu}>{anchor}</TouchableOpacity>}
      >
        <View>
          <Button
            mode="contained"
            style={{ margin: 10, marginBottom: 0, marginTop: 0, maxWidth: 120 }}
            onPress={() => onPressSave()}>
            Confirm
          </Button>
          <Button
            mode="outlined"
            style={{ margin: 10, marginBottom: 0, maxWidth: 120 }}
            onPress={() => closeMenu()}>
            Cancel
          </Button>
        </View>
      </Menu>
    </View>
  )
}
