import React, { useEffect, FC, useState } from 'react'
import { gql, useQuery } from "@apollo/client"
import { View, Text, ImageBackground, Dimensions } from "react-native"
import { Button } from "react-native-paper"
import SafeArea from "../shared/SafeArea"
import { styles } from "./styles"
import MaterialCommunityIcons from "@expo/vector-icons/MaterialCommunityIcons"
import { SelectSpace } from '@ui/MyAliensScreen/SelectSpace'
import { StartButton } from '@ui/MyAliensScreen/StartButton'
import { SpaceAliens } from '@ui/MyAliensScreen/SpaceAliens'
import { FirstSpaceHelp } from '@ui/MyAliensScreen/FirstSpaceHelp'
import SpaceSettings from '@ui/MyAliensScreen/SpaceSettings'
import { ScrollView } from 'react-native-gesture-handler'
import { useSelector } from "react-redux"
import { CreateSpace } from './CreateSpace'
import { PastFeedingAlien } from './PastFeedingAlien'

const GET_SPACES_QUERY = gql`
query getSpaces{
    getSpaces {
      _id
      name
      created
      accesses {
        username
        userId
        type
      },
      feeders {
        _id
        name
      }
  }
}`

type ScreenType = {
    navigation?: any
}

type SpaceType = {
    _id: string,
    name: string,
    created: string,
    accesses: AccessType[]
    feeders: FeederType[]
}

type AccessType = {
    userId: string
    type: string
}

type FeederType = {
    _id: string
    name: string
}

const getGridCols = () => {
    const screenWidth = Dimensions.get("window").width;
    let columns = 1
    if (screenWidth > 500 && screenWidth <= 1000) {
        columns = 2
    } else if (screenWidth > 1000 && screenWidth <= 1400) {
        columns = 3
    } else if (screenWidth > 1400) {
        columns = 4
    }
    return columns
}

const initialGridCols = getGridCols()

const MyAliensScreen: FC<ScreenType> = ({ navigation }) => {
    let calculatingColumnsTimeout: null | NodeJS.Timeout = null
    const [gridColumns, setGridColumns] = useState(initialGridCols)
    const [mySpaces, setMySpaces] = useState<SpaceType[]>([])
    const [activeSpace, setActiveSpace] = useState<SpaceType | null>(null)
    const [showSettings, setShowSettings] = useState<boolean>(false)

    const setMyAlienCardsColumns = () => {
        setGridColumns(getGridCols())
    }

    useEffect(() => {
        const changeEventListener = Dimensions.addEventListener("change", () => {
            if (calculatingColumnsTimeout != null) clearTimeout(calculatingColumnsTimeout)
            calculatingColumnsTimeout = setTimeout(setMyAlienCardsColumns, 1000)
        })
        return () => {
            changeEventListener.remove()
        }
    }, [])

    const { loading: getSpacesLoading, error: getSpacesError, data: mySpacesData, refetch: refetchMySpaces } = useQuery(GET_SPACES_QUERY, {
        fetchPolicy: 'network-only', // Used for first execution
        nextFetchPolicy: 'network-only',
        onCompleted: (response) => {
            const { getSpaces } = response
            if (getSpaces.length > 0) {
                setMySpaces(getSpaces)
                if (activeSpace) {
                    let hasFoundActiveSpace = false
                    for (let i = 0; i < getSpaces.length; i++) {
                        if (getSpaces[i]._id === activeSpace._id) {
                            hasFoundActiveSpace = true
                            changeActiveSpace(getSpaces[i])
                            break
                        }
                    }
                    if (!hasFoundActiveSpace) changeActiveSpace(getSpaces[0])
                } else {
                    changeActiveSpace(getSpaces[0])
                }
            } else {
                setMySpaces(getSpaces)
                changeActiveSpace(null)
            }
        }
    })

    const changeActiveSpace = (space: SpaceType | null) => {
        let activeSpace
        if (!space) {
            activeSpace = mySpaces.length > 0 ? mySpaces[0] : null
        } else {
            activeSpace = space
        }
        setActiveSpace(activeSpace)
    }

    if (getSpacesError) return <Text>{getSpacesError.message}</Text>
    if (getSpacesLoading || (!activeSpace && mySpaces.length !== 0)) return <Text>Loading...</Text>

    const renderTopPanel = () => {
        return (
            <View style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'row', maxWidth: '100%' }}>
                <SelectSpace spaces={mySpaces} activeSpace={activeSpace} changeActiveSpace={changeActiveSpace} />
                {showSettings &&
                    <Button
                        mode="outlined"
                        color="#ffffff"
                        labelStyle={{ borderColor: "transparent" }}
                        contentStyle={{ borderColor: "transparent" }}
                        icon={'keyboard-backspace'}
                        style={styles.backToSpaceBtn}
                        onPress={() => setShowSettings(false)}>
                        Back
                    </Button>}
                {!showSettings &&
                    <Button
                        mode="outlined"
                        color="#ffffff"
                        labelStyle={{ borderColor: "transparent" }}
                        contentStyle={{ borderColor: "transparent" }}
                        style={styles.settingsBtn}
                        onPress={() => setShowSettings(true)}>
                        <MaterialCommunityIcons
                            style={{ color: "#ffffff" }}
                            name="cog"
                            size={18}
                        />
                    </Button>}
            </View >
        )
    }
    if (mySpaces.length === 0) {
        return (
            <SafeArea>
                <ImageBackground
                    source={{ uri: "https://picsum.photos/1024" }}
                    resizeMode="cover"
                    style={{ flex: 1 }}>
                    <View style={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}>
                        <FirstSpaceHelp changeActiveSpace={changeActiveSpace} />
                    </View>
                </ImageBackground>
            </SafeArea>
        )
    }
    if (!activeSpace) return <Text>Loading...</Text>
    return (
        <SafeArea>
            <CreateSpace changeActiveSpace={changeActiveSpace} refetchMySpaces={refetchMySpaces} />
            <PastFeedingAlien feeders={activeSpace.feeders} />
            <ImageBackground
                source={{ uri: "https://picsum.photos/1024" }}
                resizeMode="cover"
                style={{ flex: 1 }}>
                <SpaceAliens
                    changeActiveSpace={changeActiveSpace}
                    setShowSettings={setShowSettings}
                    space={activeSpace}
                    gridColumns={gridColumns}
                    mySpaces={mySpaces}
                    refetchMySpaces={refetchMySpaces}
                    header={renderTopPanel}
                    isShowSettings={showSettings} />
                <StartButton navigation={navigation} activeSpaceId={activeSpace._id} />
            </ImageBackground>
        </SafeArea >
    )
}

export default MyAliensScreen
