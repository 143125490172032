// import { ids, styles } from "./styles"
import * as React from "react"
import { useEffect } from 'react'
import { View, Text } from "react-native";
import { Title, Button, Dialog, Portal, TextInput, Checkbox } from "react-native-paper"
import MaterialCommunityIcons from "@expo/vector-icons/MaterialCommunityIcons"
import { gql, useMutation, useSubscription, useQuery } from "@apollo/client";
import { TouchableOpacity } from "react-native-gesture-handler"
import moment from 'moment'
import { useDispatch, useSelector } from "react-redux"
import { toggleSettingsAlien } from "../../../../slices/myAliensSlice"

const UPDATE_ALIEN_MUTATION = gql`
  mutation Mutation($updateAlienRequest: UpdateAlienRequest) {
    updateAlien(updateAlienRequest: $updateAlienRequest) {
        _id
        name
        species
        sex
    }
  }
`;

export const Settings = () => {
    const [updateAlien, { error }] = useMutation(UPDATE_ALIEN_MUTATION)
    const dispatch = useDispatch()
    const closeMenu = () => dispatch(toggleSettingsAlien({ open: false, name: '', species: '', gender: '' }))
    const { isSettingsAlienOpen, selectedAlienId: alienId, settingsAlien }: any = useSelector(state => state)

    const [name, setName] = React.useState('')
    const [species, setSpecies] = React.useState('')
    const [sex, setSex] = React.useState('')
    useEffect(() => {
        setName(settingsAlien.name)
        setSpecies(settingsAlien.species)
        setSex(settingsAlien.gender)
    }, [settingsAlien])
    const onPressSave = async () => {
        const date = moment().toISOString()
        await updateAlien({
            variables: {
                updateAlienRequest: {
                    alienId,
                    name,
                    species,
                    sex
                },
            },
        }).then(
            () => closeMenu()
        )
    }
    return (
        <Portal>
            <Dialog
                style={{ width: '90%', maxWidth: 600, marginLeft: 'auto', marginRight: 'auto' }}
                visible={isSettingsAlienOpen}
                onDismiss={closeMenu}>
                <Dialog.Title>Alien settings</Dialog.Title>
                <Dialog.Content>
                    <TextInput
                        autoFocus={true}
                        label="Name"
                        style={{ marginBottom: 10, marginTop: 0 }}
                        mode={"outlined"}
                        value={name}
                        multiline={false}
                        numberOfLines={1}
                        onChangeText={(text) => setName(text)} />
                    <TextInput
                        label="Species"
                        style={{ marginBottom: 10, marginTop: 0 }}
                        mode={"outlined"}
                        value={species}
                        multiline={false}
                        numberOfLines={1}
                        onChangeText={(text) => setSpecies(text)} />
                    <TextInput
                        label="Gender"
                        style={{ marginBottom: 10, marginTop: 0 }}
                        mode={"outlined"}
                        value={sex}
                        multiline={false}
                        numberOfLines={1}
                        onChangeText={(text) => setSex(text)} />
                </Dialog.Content>
                <Dialog.Actions style={{ padding: 24, paddingTop: 0 }}>
                    <Button
                        mode="outlined"
                        style={{ marginRight: 10, maxWidth: 120 }}
                        onPress={() => closeMenu()}>
                        Cancel
                    </Button>
                    <Button
                        mode="contained"
                        style={{ maxWidth: 120 }}
                        onPress={() => onPressSave()}>
                        Confirm
                    </Button>
                </Dialog.Actions>
            </Dialog>
        </Portal>
    )
}
