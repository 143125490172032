import * as React from "react"
import { View, Text } from "react-native";
import { Title, Button, Menu, Divider, Provider, TextInput, Checkbox } from "react-native-paper"
import MaterialCommunityIcons from "@expo/vector-icons/MaterialCommunityIcons"
import { gql, useMutation, useSubscription, useQuery } from "@apollo/client";
import { TouchableOpacity } from "react-native-gesture-handler"
import moment from 'moment'

const DELETE_MOLT_MUTATION = gql`
mutation Mutation($deleteMoltRequest: DeleteMoltRequest) {
    deleteMolt(deleteMoltRequest: $deleteMoltRequest) {
        _id
        name
    }
  }
`;

type DeleteNoteType = {
    anchor: object
    alienId: string
    moltId: string
}

export const DeleteMolt = ({ anchor, alienId, moltId }: DeleteNoteType) => {
    const [visible, setVisible] = React.useState(false)
    const [deleteMolt, { error }] = useMutation(DELETE_MOLT_MUTATION);
    const openMenu = () => setVisible(true)
    const closeMenu = () => setVisible(false)

    const onPressSave = async () => {
        await deleteMolt({
            variables: {
                deleteMoltRequest: {
                    alienId,
                    moltId
                }
            }
        }).then(
            () => {
                closeMenu()
            }
        )
    }
    return (
        <View>
            <Menu
                visible={visible}
                onDismiss={closeMenu}
                anchor={<TouchableOpacity onPress={openMenu}>{anchor}</TouchableOpacity>}
            >
                <View style={{ padding: 15, maxWidth: 300 }}>
                    <Title style={{ paddingBottom: 10 }}>Are you sure?</Title>
                    <Text>Molting record will be removed.</Text>
                    <Button
                        mode="contained"
                        style={{ marginTop: 15, maxWidth: 120 }}
                        onPress={() => onPressSave()}>
                        Confirm
                    </Button>
                </View>
            </Menu>
        </View>
    )
}
