import React from "react"
import { createDrawerNavigator } from "@react-navigation/drawer"
import { Header } from "@ui/shared/Header"
import { theme } from '@ui/theme'
import { getHeaderTitle } from "@react-navigation/elements"
import { DrawerContentScrollView } from "@react-navigation/drawer"
import { View } from "react-native"
import { DrawerItemList, DrawerItem } from "@react-navigation/drawer"
import { UserContext } from '@ui/shared/UserContext'
import { Avatar, Text } from 'react-native-paper'
import { styles } from './styles'
import { gql, useMutation } from "@apollo/client"

type HeaderProps = {
  navigation?: object;
};


const LOGOUT_MUTATION = gql`
  mutation Mutation{
    logout
  }
`;

export const UserNavigator = ({ navigation }: HeaderProps) => {
  const [logout, { client, error }] = useMutation(LOGOUT_MUTATION)
  const Drawer = createDrawerNavigator();
  const onPressLogout = async (callbackSetUser: any) => {
    const result = await logout()
    await client.clearStore()
    callbackSetUser(null)
  }

  const CustomDrawerContent = function CustomDrawerContent({
    progress,
    ...rest
  }: any) {
    return (
      <DrawerContentScrollView {...rest}>
        <View>
          <UserContext.Consumer>
            {context => context.user ?
              <View>
                <View style={{ flexDirection: 'row' }}>
                  <Avatar.Text style={styles.userAvatar} size={70} label={context.user.username.substring(0, 2)} />
                  <View style={{ paddingTop: 23 }}>
                    <Text style={styles.userName}>{context.user.username}</Text>
                    <Text style={styles.userStatus}>online</Text>
                  </View>
                </View>
                <DrawerItemList {...rest} style={{ color: '#ffffff' }} />
                <DrawerItem
                  inactiveTintColor="#cccccc"
                  label="Logout"
                  onPress={() => onPressLogout(context.setUser)}
                // onPress={() => logout()} 
                />
              </View> : <></>}
          </UserContext.Consumer>
          {/* <DrawerItemList {...rest} style={{ color: '#ffffff' }} />
          <DrawerItem
            inactiveTintColor="#cccccc"
            label="Logout"
            onPress={() => onPressLogout()}
          // onPress={() => logout()} 
          /> */}
        </View>
      </DrawerContentScrollView>
    )
  }
  return (
    <Drawer.Navigator
      initialRouteName="My Aliens"
      drawerContent={(props) => <CustomDrawerContent {...props} />}
      screenOptions={{
        header: ({ navigation, route, options }) => {
          return (
            <Header
              subtitle={getHeaderTitle(options, route.name)}
              style={options.headerStyle}
              navigation={navigation}
            />
          );
        },
        drawerActiveTintColor: "orange",
        drawerInactiveTintColor: "#cccccc",
        drawerStyle: {
          backgroundColor: theme.colors.drawer,
        },
        sceneContainerStyle: {
          backgroundColor: 'transparent'
        }
        // DrawerItem : {
        //   activeTintColor: '#ffffff', /* font color for active screen label */
        //   activeBackgroundColor: '#ffffff', /* bg color for active screen */
        //   inactiveTintColor: '#ffffff', /* Font color for inactive screens' labels */ 
        // }
      }}
    >
      {/* Use this approach (getComponent={} vs component={}) instead of the component prop if you want the screen module to be lazily evaluated when needed. */}
      <Drawer.Screen name="My Aliens" getComponent={() => require('../../MyAliensScreen/index').default} />
      <Drawer.Screen name="Settings" getComponent={() => require('../../SettingsScreen/index').default} />
      {/* <Drawer.Screen name="Activity" getComponent={() => require('../screen/questions/QuestionsScreen').default} /> */}
      {/* <Drawer.Screen name="Blog" getComponent={() => require('../screen/questions/QuestionsScreen').default} /> */}
      {/* <Drawer.Screen name="Questions" getComponent={() => require('../screen/questions/QuestionsScreen').default} /> */}
    </Drawer.Navigator>
  )
}
