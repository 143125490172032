import { Platform } from 'react-native'
import { ApolloProvider } from '@apollo/client'
import { Provider as PaperProvider } from "react-native-paper"
import { theme } from './src/ui/theme'
import { SafeAreaProvider } from "react-native-safe-area-context/src/SafeAreaContext"
import { GestureHandlerRootView } from "react-native-gesture-handler"
import { NavigationContainer } from "@react-navigation/native";
import { client } from "./src/app/apolloClient"
import { Lock } from '@ui/shared/Lock'
import { configureStore } from '@reduxjs/toolkit'
import myAliensReducer from "./src/slices/myAliensSlice"
import { Provider } from 'react-redux'

//TODO: Continue here ---> https://github.com/emreYoleri-gitHub/Expo-Graphql-With-Apollo/blob/master/App.js

if (Platform.OS === 'web') {
  history.pushState(null, null, document.URL)
  window.addEventListener('popstate', function () {
    history.pushState(null, null, document.URL)
  })
}

const store = configureStore({
  reducer: myAliensReducer
});

export default function App() {
  return (
    <Provider store={store}>
      <ApolloProvider client={client}>
        <GestureHandlerRootView style={{ flex: 1 }}>
          <SafeAreaProvider>
            <PaperProvider theme={theme}>
              <NavigationContainer>
                <Lock />
              </NavigationContainer>
            </PaperProvider>
          </SafeAreaProvider>
        </GestureHandlerRootView>
      </ApolloProvider>
    </Provider>
  );
}

