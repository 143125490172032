// import { ids, styles } from "./styles"
import * as React from "react"
import { View, Text } from "react-native";
import { Button, Menu, Divider, Provider, TextInput, Checkbox, Title } from "react-native-paper"
import MaterialCommunityIcons from "@expo/vector-icons/MaterialCommunityIcons"
import { gql, useMutation, useSubscription, useQuery } from "@apollo/client";
import { TouchableOpacity } from "react-native-gesture-handler"
import moment from 'moment'

const REMOVE_MEMBER_MUTATION = gql`
  mutation Mutation($removeMemberData: RemoveMemberData) {
    removeMember(removeMemberData: $removeMemberData) {
        _id
        name
    }
  }
`;

type RemoveMemberType = {
    anchor: object
    spaceId: string
    userId: string
    //   alienId: string
}

type MoltType = {
    _id: string
    type: string
    date: string
    num: number
}

export const RemoveMember = ({ anchor, spaceId, userId }: RemoveMemberType) => {
    const [visible, setVisible] = React.useState(false)
    const [removeMember, { error }] = useMutation(REMOVE_MEMBER_MUTATION);
    const openMenu = () => setVisible(true)
    const closeMenu = () => setVisible(false)

    const onPressSave = async () => {
        await removeMember({
            variables: {
                removeMemberData: {
                    userId,
                    spaceId
                },
            },
        })
            .then(
                () => {
                    // setCurrentMolt('')
                    closeMenu()
                }
            ).catch((error) => {
                // just catch
            })
    }

    return (
        <View>
            <Menu
                visible={visible}
                onDismiss={closeMenu}
                anchor={<TouchableOpacity onPress={openMenu}>{anchor}</TouchableOpacity>}
            >
                <View>
                    <Title style={{ paddingLeft: 10, paddingRight: 10, paddingBottom: 10 }}>Are you sure?</Title>
                    <Text style={{ paddingLeft: 10, paddingRight: 10, paddingBottom: 10 }}>User will loose access to this space and all aliens.</Text>
                    {error && <Text style={{ color: 'red', marginLeft: 10, marginRight: 10, marginBottom: 10 }}>{error?.message}</Text>}
                    <Button
                        mode="contained"
                        style={{ margin: 10, marginBottom: 0, marginTop: 10, maxWidth: 120 }}
                        onPress={() => onPressSave()}
                    >
                        Confirm
                    </Button>
                </View>
            </Menu>
        </View>
    );
};
