import StyleSheet from "react-native-media-query";

export const { ids, styles } = StyleSheet.create({
    userAvatar: {
        margin: 10,
        maxWidth: 90,
        float: 'left'
    },
    userName: {
        fontSize: 20,
        color: '#ccc',
        float: 'left',
        maxWidth: 300
    },
    userStatus: {
        color: '#218d38'
    }
})