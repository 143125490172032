import StyleSheet from "react-native-media-query";

const { ids, styles } = StyleSheet.create({
  modal: {
    padding: 15,
    marginLeft: "auto",
    marginRight: "auto",
    maxWidth: 800,
    width: "96%",
    backgroundColor: '#f5f5f5'
  },
  badge: {
    fontSize: 11,
    marginLeft: 4,
    color: "white",
    minWidth: 90,
    alignSelf: "center",
    backgroundColor: 'transparent'
  },
  badgeWhite: {
    color: "orange",
    backgroundColor: "white",
  },
  badgeBlue: {
    color: "blue",
  },
  badgePurple: {
    color: "purple",
  },
  badgeGreen: {
    color: "green",
  },
  badgeOrange: {
    color: "orange",
  },
  iconOrange: {
    color: "orange",
  },
  iconGreen: {
    color: "green",
  },
  iconBlue: {
    color: 'blue'
  },
  activityListItem: {
    borderBottomWidth: 1,
    borderStyle: "dashed",
    borderColor: "#cccccc",
  },
  activityListItemLast: {
  },
  warningNoteSurface: {
    backgroundColor: 'orange',
    alignItems: 'center',
    justifyContent: 'center',
    elevation: 4,
    padding: 10,
    marginBottom: 10,
    flexDirection: 'row'
  },
  infoNoteSurface: {
    backgroundColor: 'lightblue',
    alignItems: 'center',
    justifyContent: 'center',
    elevation: 4,
    padding: 10,
    marginBottom: 10,
    flexDirection: 'row'
  },
  warningNoteText: {
    paddingLeft: 10,
    flex: 6,
    alignItems: 'stretch'
  },
  alienAvatar: {
    width: 64,
    height: 64,
    marginTop: 5,
    backgroundColor: 'none',
    flex: 1,
    minWidth: 70
  },
  alienGender: {
    width: 16,
    marginRight: 3,
  },
  alienSpecies: {
    // maxWidth: '100%',
    // flex: 1
  }
});

export { ids, styles };
