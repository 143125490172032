import StyleSheet from "react-native-media-query"

export const { ids, styles } = StyleSheet.create({
    modal: {
        padding: 15,
        marginLeft: "auto",
        marginRight: "auto",
        maxWidth: 500,
        width: "96%",
      },
    })