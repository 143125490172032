import { ids, styles } from "./styles";
import * as React from "react"
import { View } from "react-native"
import { Menu } from "react-native-paper"
import { TouchableOpacity } from "react-native-gesture-handler"
import { gql, useMutation, useSubscription, useQuery } from "@apollo/client";

const MOVE_ALIEN_MUTATION = gql`
  mutation Mutation($moveRequest: MoveRequest) {
    moveAlien(moveRequest: $moveRequest) {
      _id
      name
    }
  }
`;

type MoveAlienType = {
  anchor: JSX.Element
  alienId: string
  mySpaces: any
  closeModal: ()=>void
  refetchAliens: ()=>{}
}

export const MoveAlien = ({ anchor, alienId, mySpaces, refetchAliens, closeModal }: MoveAlienType) => {
  const [visible, setVisible] = React.useState(false)
  const openMenu = () => setVisible(true)
  const closeMenu = () => setVisible(false)
  const [moveAlien, { error }] = useMutation(MOVE_ALIEN_MUTATION);
  const onPressSave = async (spaceId: string) => {
    await moveAlien({
      variables: {
        moveRequest: {
          alienId,
          spaceId
        },
      },
    }).then(
      () => {
        refetchAliens()
        closeMenu()
        closeModal()
      }
    )
  }

  return (
    <View>
      <Menu
        visible={visible}
        onDismiss={closeMenu}
        anchor={
          <TouchableOpacity onPress={openMenu}>{anchor}</TouchableOpacity>
        }>
        {mySpaces && mySpaces.map((space: any) => {
          return <Menu.Item onPress={() => onPressSave(space._id)} key={space._id} title={space.name} />
        })}
      </Menu>
    </View>
  )
}
