import * as React from "react"
import { View, Text } from "react-native";
import { Title, Button, Menu, Divider, Provider, TextInput, Checkbox } from "react-native-paper"
import MaterialCommunityIcons from "@expo/vector-icons/MaterialCommunityIcons"
import { gql, useMutation, useSubscription, useQuery } from "@apollo/client";
import { TouchableOpacity } from "react-native-gesture-handler"
import moment from 'moment'

const DELETE_FEEDING_MUTATAION = gql`
mutation Mutation($deleteFeedingRequest: DeleteFeedingRequest) {
    deleteFeeding(deleteFeedingRequest: $deleteFeedingRequest) {
        _id
        name
    }
  }
`;

type DeleteFeedingType = {
    anchor: object
    alienId: string
    feedingId: string
}

export const DeleteFeeding = ({ anchor, alienId, feedingId }: DeleteFeedingType) => {
    const [visible, setVisible] = React.useState(false)
    const [deleteFeeding, { error }] = useMutation(DELETE_FEEDING_MUTATAION);
    const openMenu = () => setVisible(true)
    const closeMenu = () => setVisible(false)

    const onPressSave = async () => {
        await deleteFeeding({
            variables: {
                deleteFeedingRequest: {
                    alienId,
                    feedingId
                }
            }
        }).then(
            () => {
                closeMenu()
            }
        )
    }
    return (
        <View>
            <Menu
                visible={visible}
                onDismiss={closeMenu}
                anchor={<TouchableOpacity onPress={openMenu}>{anchor}</TouchableOpacity>}
            >
                <View style={{ padding: 15, maxWidth: 300 }}>
                    <Title style={{ paddingBottom: 10 }}>Are you sure?</Title>

                    <Text>Feeding record will be removed.</Text>
                    <Button
                        mode="contained"
                        style={{ marginTop: 15, maxWidth: 120 }}
                        onPress={() => onPressSave()}
                    >
                        Confirm
                    </Button>
                </View>
            </Menu>
        </View>
    )
}
