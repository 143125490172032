// import { ids, styles } from "./styles";
import * as React from "react";
import { View } from "react-native";
import { Menu, Divider, Provider } from "react-native-paper";
import MaterialCommunityIcons from "@expo/vector-icons/MaterialCommunityIcons";
import { TouchableOpacity } from "react-native-gesture-handler";
import { gql, useMutation, useSubscription, useQuery } from "@apollo/client";
import moment from 'moment'
import { Surface, TextInput, Title, Button, Text } from 'react-native-paper'
import SafeArea from "../../shared/SafeArea"

const CREATE_SPACE_MUTATION = gql`
  mutation Mutation($spaceData: SpaceData) {
    createSpace(spaceData: $spaceData) {
        _id,
        name,
        created,
        accesses {
            type
            userId
            username
        }
    }
  }
`;


export const FirstSpaceHelp = ({ changeActiveSpace }: any): JSX.Element => {
    const defaultName = 'My exotic farm'
    const [spaceName, setSpaceName] = React.useState(defaultName)
    const [createSpace, { error: createSpaceError }] = useMutation(CREATE_SPACE_MUTATION)

    const onPressSave = async () => {
        await createSpace({
            variables: {
                spaceData: {
                    name: spaceName
                },
            },
        }).then((response) => {
            const space = response.data.createSpace
            changeActiveSpace(space)
            setSpaceName(defaultName)
        }).catch(
            // () => console.log('errorrrr')
        )
    }

    return (
        <Surface style={{ minWidth: 400, maxWidth: 400, borderRadius: 8, padding: 20 }}>
            <Title>Start by creating your first alien space</Title>
            <TextInput
                label="Space name"
                style={{ marginTop: 10, marginBottom: 5 }}
                mode={"outlined"}
                value={spaceName}
                multiline={false}
                numberOfLines={1}
                onChangeText={(text) => setSpaceName(text)}
            />
            <Button
                mode="contained"
                style={{ marginTop: 15, marginBottom: 15, marginLeft: 'auto', marginRight: 'auto', minWidth: 150, maxWidth: 120 }}
                onPress={() => onPressSave()}>Continue</Button>
            <Text style={{ marginBottom: 5, color: '#8d8d8d' }}>You can rename it later on.</Text>
            <Text style={{ marginBottom: 5, color: '#8d8d8d' }}>Spaces group are groups of animals managed together. You can have as many as you like.</Text>
        </Surface>
    )
}