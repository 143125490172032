import React, { useState } from "react"
import { createStackNavigator } from "@react-navigation/stack"
import { UserNavigator } from "@ui/shared/UserNavigator"
import SignInScreen from "@ui/auth/SignInScreen"
import SignUpScreen from "@ui/auth/SignUpScreen"
import ForgotPassScreen from "@ui/auth/ForgotPassScreen"
import { gql, useQuery } from "@apollo/client";
import { Text } from 'react-native-paper'
import { UserContext } from "../UserContext"

const Stack = createStackNavigator()

const GET_CURRENT_USER_QUERY = gql`
query getCurrentUser{
  getCurrentUser {
      _id
      username
      email
  }
}`

export const Lock = () => {
  const [currentUser, setCurrentUser] = useState<{
    _id: string
    username: string
    email: string
  } | null>(null)
  const { loading, error, data, refetch } = useQuery(GET_CURRENT_USER_QUERY, {
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      const user = data.getCurrentUser ? data.getCurrentUser : null
      setCurrentUser(user)
    }
  })
  const updateCurrentUser = (user: any) => {
    return setCurrentUser(user)
  }
  let isAuthenticated = currentUser ? true : false
  if (error) return <Text>Internal error, please contact admin.</Text>
  if (loading) return <Text>Loading</Text>
  const guestNavigator = (
    <Stack.Navigator initialRouteName="SignInScreen">
      <Stack.Screen
        name="SignInScreen"
        component={SignInScreen}
        options={{ headerShown: false }}
      ></Stack.Screen>
      <Stack.Screen name='SignUpScreen' options={{ title: 'Sign Up to alienkeeper', headerShown: false }}>
        {(props) => <SignUpScreen {...props} refetchUser={refetch} />}
      </Stack.Screen>
      <Stack.Screen
        name="ForgotPassScreen"
        component={ForgotPassScreen}
        options={{ headerShown: false }}
      ></Stack.Screen>
    </Stack.Navigator>
  )
  const activeNavigator = !isAuthenticated ? guestNavigator : <UserNavigator />
  return <UserContext.Provider value={{ user: currentUser, setUser: updateCurrentUser }}>{activeNavigator}</UserContext.Provider>
}
