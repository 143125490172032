import { ids, styles } from "./styles"
import * as React from "react"
import { View, Text } from "react-native";
import { Dialog, Portal, Button, TextInput, Checkbox } from "react-native-paper"
import MaterialCommunityIcons from "@expo/vector-icons/MaterialCommunityIcons"
import { gql, useMutation, useSubscription, useQuery } from "@apollo/client";
import { TouchableOpacity } from "react-native-gesture-handler"
import moment from 'moment'
import { useDispatch, useSelector } from "react-redux"
import { toggleMoltAlien } from "../../../slices/myAliensSlice"

const MOLT_ALIEN_GQL = gql`
  mutation Mutation($moltRequest: MoltRequest) {
    moltAlien(moltRequest: $moltRequest) {
      _id
      name
   }
  }
`;

type MoltType = {
  _id: string
  type: string
  date: string
  num: number
}

export const MoltAlien = () => {
  const [moltAlien, { error }] = useMutation(MOLT_ALIEN_GQL);
  const dispatch = useDispatch()
  const closeMenu = () => dispatch(toggleMoltAlien({ open: false, previousMolt: null }))
  const [initialMolt, setInitial] = React.useState('0')
  const [checked, setChecked] = React.useState(false)
  const { isMoltAlienOpen, selectedAlienId: alienId, moltAlienPrevious }: any = useSelector(state => state)
  const previousMoltNum = moltAlienPrevious ? moltAlienPrevious.num : 0

  const onPressSave = async () => {
    const date = moment().toISOString()
    let type = !checked ? 'regular' : 'adult'
    if (moltAlienPrevious && moltAlienPrevious.type === 'adult') {
      type = 'adult'
    }
    let num = 1
    if (previousMoltNum === 0 && parseInt(initialMolt) !== 0) {
      num += parseInt(initialMolt)
    } else {
      num += previousMoltNum
    }
    await moltAlien({
      variables: {
        moltRequest: {
          alienId,
          molt: { type, date, num }
        },
      },
    }).then(
      () => {
        setChecked(false)
        closeMenu()
      }
    )
  }

  const initialMoltInput = <TextInput
    autoFocus={true}
    label="Last molt number"
    style={{ margin: 10, marginTop: 0 }}
    mode={"outlined"}
    value={initialMolt}
    multiline={false}
    numberOfLines={1}
    onChangeText={(moltNum) => setInitial(moltNum)}
  />

  return (
    <Portal>
      <Dialog
        style={{ width: '90%', maxWidth: 600, marginLeft: 'auto', marginRight: 'auto' }}
        visible={isMoltAlienOpen}
        onDismiss={closeMenu}>
        <Dialog.Title>Confirm molt</Dialog.Title>
        <Dialog.Content>
          {previousMoltNum === 0 && initialMoltInput}
          {(moltAlienPrevious && moltAlienPrevious.type !== 'adult') || previousMoltNum === 0 ?
            <Checkbox.Item label="Adult molt" status={checked ? "checked" : "unchecked"}
              onPress={() => {
                setChecked(!checked);
              }} /> : <></>
          }
          <Dialog.Actions style={{ padding: 24, paddingTop: 0 }}>
            <Button
              mode="outlined"
              style={{ marginBottom: 0, marginTop: 0, width: 100, marginRight: 10 }}
              onPress={() => closeMenu()}>
              Cancel
            </Button>
            <Button
              mode="contained"
              style={{ margin: 10, marginBottom: 0, marginTop: 0, maxWidth: 120 }}
              onPress={() => onPressSave()}>
              Confirm
            </Button>
          </Dialog.Actions>
        </Dialog.Content>
      </Dialog>
    </Portal>
  )
}
