import StyleSheet from "react-native-media-query";

export const { ids, styles } = StyleSheet.create({
  settingsCard: {
    elevation: 3,
    zIndex: 2,
    paddingBottom: '5px',
    backgroundColor: '#f5f5f5',
    border: '1px solid #fff',
    margin: 10,
    maxWidth:700
  },
})