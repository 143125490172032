import * as React from "react"
import { Appbar } from "react-native-paper"

type goBack = () => string

type HeaderProps = {
  title?: string | undefined
  subtitle?: string | undefined
  navigation?: any
  goBack?: goBack | undefined
  style?: any | undefined
}

type NavigationType = {

}

export const Header = ({ title, subtitle, navigation, goBack }: HeaderProps) => {
  const [visible, setVisible] = React.useState(false)
  const openMenu = () => setVisible(true)
  const closeMenu = () => setVisible(false)
  if (!title) title = "Alienkeeper"
  return (
    <Appbar.Header>
      <Appbar.Action icon={"menu"} onPress={navigation.openDrawer} />
      <Appbar.Content
        title={title}
        subtitle={subtitle}
        style={{ marginLeft: 0 }}
      />
    </Appbar.Header>
  )
}
