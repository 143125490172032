import * as React from 'react'
import { FAB, Portal } from 'react-native-paper'
import { RefObject } from 'react'
import { NewAlien } from '@ui/MyAliensScreen/NewAlien'


type StartButtonType = {
    navigation?: any
    activeSpaceId?: string | null
};

export const StartButton = ({ navigation, activeSpaceId }: StartButtonType) => {
    const [state, setState] = React.useState({ open: false })
    const onStateChange = ({ open }: any) => setState({ open })
    const { open } = state
    const NewAlienModalRef: RefObject<any> = React.createRef();
    const NewAlienModal = <NewAlien ref={NewAlienModalRef} activeSpaceId={activeSpaceId} />;
    if (!activeSpaceId) return (<></>)
    return (
        <Portal>
            {NewAlienModal}
            <FAB.Group
                visible={true}
                open={open}
                icon={open ? 'calendar-today' : 'plus'}
                actions={[
                    {
                        icon: 'plus',
                        label: 'Add alien',
                        onPress: () => NewAlienModalRef.current?.open()
                    },
                ]}
                onStateChange={onStateChange}
            />
        </Portal>
    )
}

