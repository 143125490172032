import React, { useEffect } from "react"
import { View } from "react-native"
import { Text, RadioButton, Button, TextInput, Headline, Title, Portal } from "react-native-paper"
import { Modalize } from "react-native-modalize"
import { styles } from "./styles"
import { gql, useMutation, useSubscription, useQuery } from "@apollo/client";
import ObjectID from 'bson-objectid'

const CREATE_ALIEN_MUTATION = gql`
  mutation Mutation($alienData: AlienData) {
    createAlien(alienData: $alienData) {
        _id
        name
        species
        sex
        type
    }
  }
`;

const DEFAULT_ALIEN_TYPE = 'spider'

export const NewAlien = React.forwardRef(({ activeSpaceId }: any, ref: any) => {
    const [name, setName] = React.useState('')
    const [species, setSpecies] = React.useState('')
    const [sex, setSex] = React.useState('female')
    const [type, setType] = React.useState(DEFAULT_ALIEN_TYPE)
    const [createAlien, { error: createAlienError }] = useMutation(CREATE_ALIEN_MUTATION)
    const resetInputs = () => {
        setName('')
        setSpecies('')
        setSex('female')
        setType(DEFAULT_ALIEN_TYPE)
    }
    const onPressSave = async () => {
        ref.current?.close() // todo: does not get called after "createAlien"
        await createAlien({
            variables: {
                alienData: {
                    spaceId: activeSpaceId,
                    name,
                    species,
                    sex,
                    type,
                },
            },
        }).then(() => {
            resetInputs()
        }).catch(
            // (error) => console.log(error)
        )

    };
    // if (createAlienError) { console.log(createAlienError) }
    return (
        <Portal>
            <Modalize ref={ref} modalStyle={styles.modal}>
                <View style={{ flexDirection: "row", marginBottom: 15 }}>
                    <View style={{ paddingLeft: 15 }}>
                        <Headline>New alien</Headline>
                        <Title style={{ fontSize: 16, color: "#727272" }}>
                            Who are we keeping?
                        </Title>
                    </View>
                </View>
                <View style={{ display: "flex", flexDirection: "column" }}>
                    <TextInput
                        label="Name"
                        style={{ margin: 10, marginTop: 0 }}
                        mode={"outlined"}
                        value={name}
                        multiline={true}
                        numberOfLines={1}
                        onChangeText={(text) => setName(text)}
                    />
                    <TextInput
                        label="Species"
                        style={{ margin: 10, marginTop: 0 }}
                        mode={"outlined"}
                        value={species}
                        multiline={true}
                        numberOfLines={1}
                        onChangeText={(text) => setSpecies(text)}
                    />
                    <TextInput
                        label="Sex"
                        style={{ margin: 10, marginTop: 0 }}
                        mode={"outlined"}
                        value={sex}
                        multiline={true}
                        numberOfLines={1}
                        onChangeText={(text) => setSex(text)}
                    />
                    <RadioButton.Group onValueChange={text => setType(text)} value={type}>
                        <View style={{ flex: 1, flexDirection: 'row', margin: 10 }}>
                            <View style={{ flex: 1 }}>
                                <Text>Spider</Text>
                                <RadioButton value="spider" />
                            </View>
                            <View style={{ flex: 1 }}>
                                <Text>Mantis</Text>
                                <RadioButton value="mantis" />
                            </View>
                            <View style={{ flex: 1 }}>
                                <Text>Lizard</Text>
                                <RadioButton value="lizard" />
                            </View>
                            <View style={{ flex: 1 }}>
                                <Text>Snake</Text>
                                <RadioButton value="snake" />
                            </View>
                            <View style={{ flex: 1 }}>
                                <Text>Other</Text>
                                <RadioButton value="other" />
                            </View>
                        </View>
                    </RadioButton.Group>
                    <Button
                        icon="check"
                        mode="contained"
                        style={{ marginBottom: 10, width: "50%", marginLeft: 'auto', marginRight: 'auto', marginTop: '10px' }}
                        onPress={() => onPressSave()}
                    >
                        Save
                    </Button>
                </View>
            </Modalize>
        </Portal>
    )
})
