import { ids, styles } from "./styles"
import * as React from "react"
import { View, Text } from "react-native";
import { Button, Dialog, Portal, TextInput, Checkbox } from "react-native-paper"
import MaterialCommunityIcons from "@expo/vector-icons/MaterialCommunityIcons"
import { gql, useMutation, useSubscription, useQuery } from "@apollo/client";
import { TouchableOpacity } from "react-native-gesture-handler"
import moment from 'moment'

const INVITE_MEMBER_MUTATION = gql`
  mutation Mutation($inviteData: InviteData) {
    addMember(inviteData: $inviteData) {
        _id,
        name,
        created,
        accesses {
            type
            userId
        }
    }
  }
`;

type InviteMemberType = {
  anchor: object,
  spaceId: string
}

export const InviteMember = ({ anchor, spaceId }: InviteMemberType) => {
  const [visible, setVisible] = React.useState(false)
  const [inviteMember, { error }] = useMutation(INVITE_MEMBER_MUTATION);
  const openMenu = () => setVisible(true)
  const closeMenu = () => setVisible(false)
  const [usernameOrEmail, setUsernameOrEmail] = React.useState('')
  // const [checked, setChecked] = React.useState(false)

  const onPressSave = async () => {
    await inviteMember({
      variables: {
        inviteData: { usernameOrEmail, spaceId }
      }
    }).then(
      () => {
        setUsernameOrEmail('')
        closeMenu()
      }
    ).catch(
      (error) => {
        // just catch
      }
    )
  }
  return (
    <View>
      <TouchableOpacity onPress={openMenu}>{anchor}</TouchableOpacity>
      <Portal>
        <Dialog
          style={{ width: '90%', maxWidth: 600, marginLeft: 'auto', marginRight: 'auto' }}
          visible={visible}
          onDismiss={closeMenu}>
          <Dialog.Title>Invite member</Dialog.Title>
          <Dialog.Content>
            <TextInput
              autoFocus={true}
              label="Username or email"
              mode={"outlined"}
              value={usernameOrEmail}
              multiline={false}
              numberOfLines={1}
              onChangeText={(text) => setUsernameOrEmail(text)}
            />
            {error && <Text style={{ color: 'red', marginLeft: 10, marginRight: 10, marginBottom: 10 }}>{error?.message}</Text>}

          </Dialog.Content>
          <Dialog.Actions style={{ padding: 24, paddingTop: 0 }}>
            <Button
              mode="outlined"
              style={{ marginRight: 10, maxWidth: 120 }}
              onPress={() => closeMenu()}>
              Cancel
            </Button>
            <Button
              mode="contained"
              style={{ maxWidth: 100 }}
              onPress={() => onPressSave()}>
              Invite
            </Button>
          </Dialog.Actions>
        </Dialog>
      </Portal>
    </View>
  );
};
