import * as React from "react"
import { View } from "react-native"
import { Menu, Divider } from "react-native-paper"
import { TouchableOpacity } from "react-native-gesture-handler"
import { gql, useMutation, } from "@apollo/client"
import moment from 'moment'
import { useDispatch } from "react-redux"
import { togglePastFeedingAlien, togglePastFeedingModalAlien } from "../../../slices/myAliensSlice"

const FEED_ALIEN_MUTATION = gql`
  mutation Mutation($feedingRequest: FeedingRequest) {
    feedAlien(feedingRequest: $feedingRequest)  {
      _id
      name
      species
      sex
   }
  }
`;

type FeedAlienType = {
  anchor: object
  alienId: string
  feeders: FeederType[]
  closeParent?: () => void
  isModal?: boolean | undefined
}

type FeederType = {
  _id: string
  name: string
}

export const FeedAlien = ({ anchor, alienId, feeders, closeParent, isModal }: FeedAlienType) => {
  const [visible, setVisible] = React.useState(false)
  const [feedAlien, { error }] = useMutation(FEED_ALIEN_MUTATION)
  const openMenu = () => setVisible(true)
  const closeMenu = () => setVisible(false)
  const dispatch = useDispatch()

  const onPressFeed = async (name: string) => {
    const date = moment().toISOString()
    await feedAlien({
      variables: {
        feedingRequest: {
          alienId,
          feeding: {
            type: 'ok',
            date,
            name,
          }
        },
      },
    }).then(
      () => {
        closeMenu()
        if (closeParent) closeParent()
      }
    )
  }
  const onPressPastFeed = async () => {
    closeMenu()
    if (closeParent) closeParent()
    if (isModal) {
      dispatch(togglePastFeedingModalAlien({ isOpen: true, alienId }))
    } else {
      dispatch(togglePastFeedingAlien({ isOpen: true, alienId }))
    }
  }

  return (
    <View>
      <Menu
        visible={visible}
        onDismiss={closeMenu}
        anchor={<TouchableOpacity onPress={openMenu}>{anchor}</TouchableOpacity>}>
        {
          feeders.map((feeder) => {
            return <Menu.Item onPress={() => onPressFeed(feeder.name)} key={feeder._id} title={feeder.name} />
          })
        }
        <Divider />
        <Menu.Item onPress={onPressPastFeed} title="Earlier date" />
      </Menu>
    </View >
  )
}