import { ids, styles } from "./styles";
import * as React from "react";
import { View } from "react-native";
import { Card, Text } from "react-native-paper";
import { DeleteSpace } from '@ui/MyAliensScreen/SpaceSettings/DeleteSpace'

export const OtherSettings = ({ space, setShowSettings, changeActiveSpace }: any): JSX.Element => {
    return (
        <Card style={styles.settingsCard} dataSet={{ media: ids.settingsCard }}>
            <Card.Title title={'Other'} />
            <View style={{ padding: 16 }}>
                <DeleteSpace spaceId={space._id} setShowSettings={setShowSettings} changeActiveSpace={changeActiveSpace} anchor={<Text style={{ marginTop: 15, color: '#dd8017' }}>Delete this space and all aliens?</Text>} />
            </View>
        </Card>
    )
}