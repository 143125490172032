import { nominalTypeHack } from "prop-types";
import StyleSheet from "react-native-media-query";

const { ids, styles } = StyleSheet.create({
  myAlienCard: {
    elevation: 3,
    zIndex: 2,
    paddingBottom: '5px',
    backgroundColor: '#f5f5f5',
    border: '1px solid #fff',
    margin: 10,
  },
  mainRow: {
    flexDirection: "row",
  },
  alienAvatar: {
    width: 60,
    height: 60,
    flexGrow: 0,
    flexShrink: 0,
    borderRadius: 50,
    marginLeft: 10,
    marginTop: 10,
  },
  alienName: {
    flexGrow: 3,
    flexShrink: 1,
  },
  alienMoreOptsWrapper: {
    flexGrow: 0,
    flexShrink: 1,
    marginTop: 5,
    marginRight: 5,
    width: 45,
    position: "relative"
  },
  genderSpecies: {
    display: 'flex',
    flexDirection: 'row',
    // textAlign:'left'
  },
  alienSpecies: {
    marginRight: 3,
    maxWidth: '100%',
    overflow: 'hidden'
  },
  alienGender: {
    width: 16,
    marginRight: 2,
  },
  alienLastFed: {
    width: 16,
    marginRight: 5,
  },
  alienActionsRow: {
    display: 'flex',
  },
  alienActionsFirst: {
    marginLeft: 'auto',
  },
  alienActionsLast: {
    marginRight: 0,
  },
  alienActions: {
    backgroundColor: 'green',
    color: 'white',
    fontSize: 16,
    borderRadius: 50,
    width: 32,
    height: 32,
    marginRight: 5,
    padding: 8,
  },
  alienLastFedItem: {
    padding: 0,
    marginLeft: 5,
    marginRight: 5,
    marginTop: 5,
    marginBottom: 0,
    border: 0,
    borderBottom: "1px dashed #ccc",
  },
  alienLastFedItemNoborder: {
    padding: 0,
    marginLeft: 5,
    marginRight: 5,
    marginTop: 5,
    marginBottom: 0,
    border: 0,
    borderBottom: 0,
  },
  badgeBlue: {
    color: "blue",
  },
  badgePurple: {
    color: "purple",
  },
  badgeGreen: {
    color: "green",
  },
  badgeOrange: {
    color: "orange",
  },
  iconOrange: {
    color: "orange",
  },
  iconGreen: {
    color: "green",
  },
  iconBlue: {
    color: 'blue'
  },
  badge: {
    fontSize: 11,
    marginLeft: 4,
    color: "white",
    minWidth: 90,
    alignSelf: "center",
    backgroundColor: 'transparent'
  },
  activityListItem: {
    borderBottomWidth: 1,
    borderStyle: "dashed",
    borderColor: "#cccccc",
    paddingTop: 3,
    paddingBottom: 3,
  },
  activityListItemLast: {
    paddingTop: 3,
    paddingBottom: 3,
  },
})

export { ids, styles };
