
import React, { useEffect, FC, useState } from 'react'
import { Button, Dialog, Portal, TextInput, Menu, Text } from 'react-native-paper'
import { View, TouchableOpacity } from 'react-native'
import { useDispatch, useSelector } from "react-redux"
import { togglePastFeedingAlien, togglePastFeedingModalAlien } from "../../../slices/myAliensSlice"
import moment from 'moment'
import { gql, useMutation, } from "@apollo/client"
import { DatePickerModal, TimePickerModal } from 'react-native-paper-dates'

const FEED_ALIEN_MUTATION = gql`
  mutation Mutation($feedingRequest: FeedingRequest) {
    feedAlien(feedingRequest: $feedingRequest)  {
      _id
      name
      species
      sex
   }
  }
`;

type FeedPastAlienType = {
  feeders: FeederType[]
  isModal?: boolean
}

type FeederType = {
  _id: string
  name: string
}

const TYPE_FEEDING_OK = 'ok'
const YESTERDAY_DATE = moment().subtract(1, 'day').toDate()

export const PastFeedingAlien = ({ feeders, isModal }: FeedPastAlienType) => {
  const [openFoodpicker, setOpenFoodpicker] = React.useState(false)
  const [visibleTimepicker, setVisibleTimepicker] = React.useState(false)
  const [pastDate, setPastDate] = React.useState<Date>(YESTERDAY_DATE)
  const [pastTime, setPastTime] = React.useState<any>({ hours: 13, minutes: 0 })
  const [pastFood, setPastFood] = React.useState<string>(feeders[0].name)
  const [openDatepicker, setOpenDatepicker] = React.useState(false)
  let pastFeedingData: { isOpen: boolean, alienId: string | null }
  if (isModal) {
    const { pastFeedingModal }: any = useSelector(state => state)
    pastFeedingData = pastFeedingModal
  } else {
    const { pastFeeding }: any = useSelector(state => state)
    pastFeedingData = pastFeeding
  }
  const [feedAlien, { error }] = useMutation(FEED_ALIEN_MUTATION)
  const dispatch = useDispatch()
  const closeMenu = () => {
    isModal ? dispatch(togglePastFeedingModalAlien(false)) : dispatch(togglePastFeedingAlien(false))
  }
  const onPressPastFeed = async () => {
    const pastDateString = moment(pastDate).set('hour', pastTime.hours).set('minute', pastTime.minutes).toDate()
    await feedAlien({
      variables: {
        feedingRequest: {
          alienId: pastFeedingData.alienId,
          feeding: {
            type: TYPE_FEEDING_OK,
            date: pastDateString,
            name: pastFood,
          }
        },
      },
    }).then(
      (response) => {
        closeMenu()
      }
    ).catch(
      // (error) => console.log(error)
    )
  }
  const onDismissTimepicker = React.useCallback(() => {
    setVisibleTimepicker(false)
  }, [])
  const onConfirmTimepicker = React.useCallback(
    ({ hours, minutes }) => {
      setVisibleTimepicker(false);
      setPastTime({ hours, minutes })
    },
    [setVisibleTimepicker]
  )
  const onConfirmDatepicker = React.useCallback(
    (params) => {
      setOpenDatepicker(false);
      setPastDate(params.date);
    },
    [setOpenDatepicker, setPastDate]
  )
  const onDismissDatepicker = React.useCallback(() => {
    setOpenDatepicker(false)
  }, [setOpenDatepicker])

  let minutes = pastTime.minutes
  if (pastTime.minutes < 10) {
    minutes = '0' + pastTime.minutes
  } else if (pastTime.minutes < 0) {
    // some kind of a bugger
    minutes = '00'
  }
  const pastTimeInputVal = pastTime.hours + ':' + minutes
  return (
    <Portal>
      <Dialog
        style={{ width: '90%', maxWidth: 600, marginLeft: 'auto', marginRight: 'auto' }}
        visible={pastFeedingData.isOpen}
        onDismiss={closeMenu}>
        <Dialog.Title>Add past feeding</Dialog.Title>
        <Dialog.Content>
          <View style={{ display: 'flex', flexDirection: 'row' }}>
            <View style={{ flex: 6, marginBottom: 10 }}>
              <TouchableOpacity onPress={() => setOpenDatepicker(true)}>
                <TextInput
                  label="Date"
                  style={{ margin: 0 }}
                  mode={"outlined"}
                  value={moment(pastDate).format('MMMM Do YYYY')}
                  multiline={false}
                  numberOfLines={1}
                  onChangeText={(text) => false}
                />
              </TouchableOpacity>
            </View>
            <View style={{ flex: 4 }}>
              <Button onPress={() => setOpenDatepicker(true)} uppercase={false} mode="contained" style={{ width: 120, marginTop: 15, marginLeft: 5 }}>
                PICK DATE
              </Button>
            </View>
          </View>
          <View style={{ display: 'flex', flexDirection: 'row' }}>
            <View style={{ flex: 6, marginBottom: 10 }}>
              <TouchableOpacity onPress={() => setVisibleTimepicker(true)}>
                <TextInput
                  pointerEvents="none"
                  label="Time"
                  style={{ margin: 0 }}
                  mode={"outlined"}
                  value={pastTimeInputVal}
                  multiline={false}
                  numberOfLines={1}
                  onChangeText={(text) => false}
                />
              </TouchableOpacity>
            </View>
            <View style={{ flex: 4 }}>
              <Button onPress={() => setVisibleTimepicker(true)} uppercase={false} mode="contained" style={{ width: 120, marginTop: 15, marginLeft: 5 }}>
                PICK TIME
              </Button>
            </View>
          </View>
          <View style={{ display: 'flex', flexDirection: 'row' }}>
            <View style={{ flex: 6, marginBottom: 10 }}>
              <TouchableOpacity onPress={() => setOpenFoodpicker(true)}>
                <TextInput
                  label="Food"
                  style={{ margin: 0 }}
                  mode={"outlined"}
                  value={pastFood}
                  multiline={false}
                  numberOfLines={1}
                  onChangeText={(text) => false}
                />
              </TouchableOpacity>
            </View>
            <View style={{ flex: 4 }}>
              <Menu
                visible={openFoodpicker}
                onDismiss={() => setOpenFoodpicker(false)}
                anchor={<TouchableOpacity onPress={() => setOpenFoodpicker(true)}>
                  <Button onPress={() => setOpenFoodpicker(true)} uppercase={false} mode="contained" style={{ width: 120, marginTop: 15, marginLeft: 5 }}>
                    PICK FOOD
                  </Button>
                </TouchableOpacity>}
              >
                {
                  feeders.map((feeder) => {
                    return <Menu.Item onPress={() => { setPastFood(feeder.name); setOpenFoodpicker(false) }} key={feeder._id} title={feeder.name} />
                  })
                }
              </Menu>
            </View>
          </View>
          <TimePickerModal
            visible={visibleTimepicker}
            onDismiss={onDismissTimepicker}
            onConfirm={onConfirmTimepicker}
            hours={13} // default: current hours
            minutes={0} // default: current minutes
            label="Select time" // optional, default 'Select time'
            uppercase={false} // optional, default is true
            cancelLabel="Cancel" // optional, default: 'Cancel'
            confirmLabel="Ok" // optional, default: 'Ok'
            animationType="fade" // optional, default is 'none'
            locale="en" // optional, default is automically detected by your system
          />
          <DatePickerModal
            locale="en"
            mode="single"
            visible={openDatepicker}
            onDismiss={onDismissDatepicker}
            date={pastDate}
            onConfirm={onConfirmDatepicker}
            validRange={{
              // startDate: new Date(2021, 1, 2),  // optional
              endDate: new Date(), // optional
              // disabledDates: [new Date()] // optional
            }}
            // onChange={} // same props as onConfirm but triggered without confirmed by user
            // saveLabel="Save" // optional
            // uppercase={false} // optional, default is true
            label="Select past feeding date" // optional
          // animationType="slide" // optional, default is 'slide' on ios/android and 'none' on web
          />
          {error && <Text style={{ color: 'red' }}>{error.message}</Text>}

        </Dialog.Content>

        <Dialog.Actions style={{ padding: 24, paddingTop: 0 }}>
          <Button
            mode="outlined"
            style={{ marginBottom: 0, marginTop: 0, width: 100, marginRight: 10 }}
            onPress={closeMenu}>
            Cancel
          </Button>
          <Button
            mode="contained"
            style={{ maxWidth: 120 }}
            onPress={onPressPastFeed}>
            Confirm
          </Button>
        </Dialog.Actions>
      </Dialog>
    </Portal>
  )
}