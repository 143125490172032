import React, {useEffect} from "react"
import { Button, Dialog, Portal, TextInput } from "react-native-paper"
import { gql, useMutation } from "@apollo/client";
import moment from 'moment'
import { toggleDndAlien } from "../../../../slices/myAliensSlice"
import { useDispatch, useSelector } from "react-redux"

const DND_ALIEN_GQL = gql`
  mutation Mutation($dndRequest: DndRequest) {
    dndAlien(dndRequest: $dndRequest) {
      _id
      spaceId
    }
  }
`;

export const Dnd = () => {
  const [dndAlien, { error }] = useMutation(DND_ALIEN_GQL);
  const dispatch = useDispatch()
  const closeMenu = () => dispatch(toggleDndAlien(false))
  const [text, setText] = React.useState('')
  const { isDndAlienOpen, selectedAlienId: alienId }: any = useSelector(state => state)

  const onPressSave = async (isSetDndRequest: boolean) => {
    const date = moment().toISOString()
    const action = isSetDndRequest ? 'dnd' : 'nodnd'
    await dndAlien({
      variables: {
        dndRequest: {
          alienId,
          dnd: {
            date,
            action,
            text
          }
        },
      },
    }).then(
      () => {
        setText('')
        closeMenu()
      }
    )
  }
  return (
    <Portal>
      <Dialog
        style={{ width: '90%', maxWidth: 600, marginLeft: 'auto', marginRight: 'auto' }}
        visible={isDndAlienOpen}
        onDismiss={closeMenu}>
        <Dialog.Title>Do not disturb</Dialog.Title>
        <Dialog.Content>
          <TextInput
            autoFocus={true}
            label="Message"
            mode={"outlined"}
            value={text}
            multiline={true}
            numberOfLines={3}
            onChangeText={(text) => setText(text)}
          />
        </Dialog.Content>
        <Dialog.Actions style={{ padding: 24, paddingTop: 0 }}>
          <Button
            mode="outlined"
            style={{ width: 100, marginRight: 10 }}
            onPress={() => closeMenu()}>
            Cancel
          </Button>
          <Button
            mode="contained"
            style={{ maxWidth: 120 }}
            onPress={() => onPressSave(true)}>
            Confirm
          </Button>
        </Dialog.Actions>
      </Dialog>
    </Portal>
  )
}
