import React, { useEffect, FC, useState } from 'react'
import { gql, useMutation, useSubscription, useQuery, useLazyQuery } from "@apollo/client"
import { View, Text, TouchableOpacity, StyleSheet, ImageBackground, Dimensions } from "react-native"
import SafeArea from "../../shared/SafeArea"
import { Surface, TextInput, Title, Button } from 'react-native-paper'
import { UserContext } from '@ui/shared/UserContext'
type ScreenType = {
    navigation?: any
}

const LOGIN_MUTATION = gql`
  mutation Mutation($usernameOrEmail: String, $password: String) {
    login(usernameOrEmail: $usernameOrEmail, password: $password) {
        username,
        email
    }
  }
`;

const SignInScreen: FC<ScreenType> = ({ navigation }) => {
    const [usernameOrEmail, setUsernameOrEmail] = React.useState('')
    const [password, setPassword] = React.useState('')
    const [login, { error }] = useMutation(LOGIN_MUTATION)
    const resetInputs = () => {
        setUsernameOrEmail('')
        setPassword('')
    }
    const onPressSave = async (callbackSetUser: any) => {
        await login({
            variables: {
                // _id: new ObjectID().toHexString(),
                usernameOrEmail,
                password,
            },
        }).then((result) => {
            const user = result.data.login
            callbackSetUser(user)
            // resetInputs()
            // navigation.navigate('MyAliensScreen')
        }).catch(
            // (err) => console.log(err.message)
        )

    };

    return (
        <SafeArea>
            <ImageBackground
                source={{ uri: "https://picsum.photos/1024" }}
                resizeMode="cover"
                style={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}
            >
                <Surface style={{ minWidth: 400, maxWidth: 700, minHeight: 300, borderRadius: 8, padding: 20 }}>
                    <View style={{ display: "flex", flexDirection: "column" }}>
                        <Title style={{ marginTop: 15, marginBottom: 10, color: "#727272" }}>
                            Greetings alienkeeper!
                        </Title>
                        <TextInput
                            label="Username or email"
                            style={{ marginTop: 0 }}
                            mode={"outlined"}
                            value={usernameOrEmail}
                            multiline={false}
                            numberOfLines={1}
                            onChangeText={(text) => setUsernameOrEmail(text)}
                        />
                        <TextInput
                            secureTextEntry={true}
                            label="Password"
                            style={{ marginTop: 10 }}
                            mode={"outlined"}
                            value={password}
                            multiline={false}
                            numberOfLines={1}
                            onChangeText={(text) => setPassword(text)}
                        />
                    </View>

                    {error && <Text style={{ color: 'red', marginLeft: 10, marginRight: 10, marginBottom: 10 }}>{error?.message}</Text>}

                    <UserContext.Consumer>
                        {({ user, setUser }) => (
                            <Button
                                mode="contained"
                                style={{ marginTop: 15, marginBottom: 0, marginLeft: 'auto', marginRight: 'auto', minWidth: 150, maxWidth: 120 }}
                                onPress={() => onPressSave(setUser)}>Login</Button>
                        )}
                    </UserContext.Consumer>

                    <View style={{ flex: 1, flexDirection: 'row', marginTop: 15 }}>
                        <TouchableOpacity onPress={() => navigation.navigate('ForgotPassScreen')} style={{ marginTop: 10, flex: 1 }}>
                            <Text style={{ color: '#767676' }}>Forgot password?</Text>
                        </TouchableOpacity>
                        <TouchableOpacity onPress={() => navigation.navigate('SignUpScreen')} style={{ marginTop: 10, flex: 1 }}>
                            <Text style={{ color: '#767676', textAlign: 'right' }}>Sign up here, it's free.</Text>
                        </TouchableOpacity>
                    </View>
                </Surface>
            </ImageBackground>
        </SafeArea >
    )
}

export default SignInScreen
