import React, {  FC } from 'react'
import { gql, useMutation} from "@apollo/client"
import { View, Text } from "react-native"
import { ids, styles } from "./styles"

import { Card, TextInput, Button } from "react-native-paper"

const UPDATE_PROFILE_MUTATION = gql`
  mutation Mutation($profileData: ProfileData) {
    updateProfile(profileData: $profileData) {
        _id,
        username,
        email,
        created
    }
  }
`;

type ScreenType = {
    navigation?: any
    user: UserType
}

type UserType = {
    _id: string,
    username: string,
    email: string
}

const Profile: FC<ScreenType> = ({ navigation, user }) => {
    // const { user } = useUserContext()
    // if (!user) return <></>
    const [updateProfile, { error }] = useMutation(UPDATE_PROFILE_MUTATION);
    const [username, setUsername] = React.useState<string>(user.username)
    const [email, setEmail] = React.useState<string>(user.email)
    const onPressSave = async () => {
        await updateProfile({
            variables: {
                profileData: { username, email }
            }
        }).then(
            //   () => {
            // setUsernameOrEmail('')
            // closeMenu()
            //   }
        )
    }
    return (
        <Card style={styles.settingsCard} dataSet={{ media: ids.settingsCard }}>
            <View >
                {/* <Card.Cover
                            style={styles.alienAvatar}
                            source={{ uri: "https://picsum.photos/700" }}
                        /> */}
                <Card.Title
                    // style={styles.alienName}
                    title={'User profile'}
                    subtitle={'Manage your personal information'}
                />
            </View>
            <View style={{ padding: 16, paddingTop: 0 }}>
                <TextInput
                    label="Username"
                    style={{ marginTop: 5, marginBottom: 5 }}
                    mode={"outlined"}
                    value={username}
                    multiline={false}
                    numberOfLines={1}
                    onChangeText={(text) => setUsername(text)}
                />
                <TextInput
                    label="Email"
                    style={{ marginTop: 10, marginBottom: 5 }}
                    mode={"outlined"}
                    value={email}
                    multiline={false}
                    numberOfLines={1}
                    onChangeText={(text) => setEmail(text)}
                />
                {error && <Text style={{ color: 'red', marginLeft: 10, marginRight: 10, marginBottom: 10 }}>{error?.message}</Text>}
                <Button mode="contained" style={{ marginTop: 15, maxWidth: 200 }} onPress={() => onPressSave()} >Update</Button>
                <Text style={{ marginTop: 15, color: 'rgba(0, 0, 0, 0.54);' }}>Username is always public. Email is private and won't be shared with any third parties. Both have to be unique.</Text>
            </View>
        </Card>
    )
}

export default Profile