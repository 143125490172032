import { ids, styles } from "./styles"
import * as React from "react"
import { View } from "react-native"
import { Menu } from "react-native-paper"
import MaterialCommunityIcons from "@expo/vector-icons/MaterialCommunityIcons"
import { TouchableOpacity } from "react-native-gesture-handler"
import { FeedAlien } from "@ui/MyAliensScreen/FeedAlien"
import { RejectedFoodAlien } from "@ui/MyAliensScreen/RejectedFoodAlien"
import { NoteAlien } from "@ui/MyAliensScreen/NoteAlien"
// import { useDispatch } from "react-redux"
// import { toggleNoteAlien } from "../../../../slices/myAliensSlice"

type AlienActionsType = {
  alienId: string
  feeders: FeederType[]
}

type FeederType = {
  _id: string
  name: string
}

export const AlienActions = ({ alienId, feeders }: AlienActionsType): JSX.Element => {
  const [visible, setVisible] = React.useState(false);
  const openMenu = () => setVisible(true);
  const closeMenu = () => setVisible(false);
  // const dispatch = useDispatch()

  // const onAddNoteClick = () => {
  //   closeMenu()
  //   dispatch(toggleNoteAlien(true))
  // }

  return (
    <View>
      <Menu
        visible={visible}
        onDismiss={closeMenu}
        anchor={
          <TouchableOpacity onPress={openMenu}>
            <MaterialCommunityIcons
              style={{ color: "gray" }}
              name="dots-horizontal"
              size={32}
              color="gray"
            />
          </TouchableOpacity>
        }
      >
        <FeedAlien
          closeParent={closeMenu}
          alienId={alienId}
          feeders={feeders}
          anchor={
            <Menu.Item title="Feed" />
          }
        />
        <RejectedFoodAlien
          closeParent={closeMenu}
          alienId={alienId}
          feeders={feeders}
          anchor={
            <Menu.Item title="Rejected food" />
          }
        />
        {/* AddNote here does not work due to unset "selectedAlienId in redux" */}
        {/* <Menu.Item title="Note" onPress={() => onAddNoteClick()} /> */}
      </Menu>
    </View>
  )
}