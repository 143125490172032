import * as React from "react"
import { View, Text } from "react-native";
import { Button, Menu, Divider, Provider, TextInput, Checkbox } from "react-native-paper"
import MaterialCommunityIcons from "@expo/vector-icons/MaterialCommunityIcons"
import { gql, useMutation, useSubscription, useQuery } from "@apollo/client";
import { TouchableOpacity } from "react-native-gesture-handler"
import moment from 'moment'

const DND_ALIEN_GQL = gql`
  mutation Mutation($dndRequest: DndRequest) {
    dndAlien(dndRequest: $dndRequest) {
      _id
      name
    }
  }
`;

type DndAlienType = {
  anchor: object
  alienId: string
}

export const CancelDnd = ({ anchor, alienId }: DndAlienType) => {
  const [visible, setVisible] = React.useState(false)
  const [dndAlien, { error }] = useMutation(DND_ALIEN_GQL);
  const openMenu = () => setVisible(true)
  const closeMenu = () => setVisible(false)
  const [text, setText] = React.useState('')

  const onPressSave = async () => {
    const date = moment().toISOString()
    await dndAlien({
      variables: {
        dndRequest: {
          alienId,
          dnd: {
            date,
            action: 'nodnd',
            text
          }
        },
      },
    })
      .then(
        () => {
          closeMenu()
        }
      )
  }
  return (
    <View>
      <Menu
        visible={visible}
        onDismiss={closeMenu}
        anchor={<TouchableOpacity onPress={openMenu}>{anchor}</TouchableOpacity>}
      >
        <View>
          <Button
            mode="contained"
            style={{ margin: 10, marginBottom: 0, marginTop: 0, maxWidth: 120 }}
            onPress={() => onPressSave()}>
            Confirm
          </Button>
        </View>
      </Menu>
    </View>
  )
}
