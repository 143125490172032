import React, { FC } from 'react'
import { ImageBackground } from "react-native";
import SafeArea from "../shared/SafeArea"
import { useUserContext } from '@ui/shared/UserContext'
import Profile from '@ui/SettingsScreen/Profile'
import Password from '@ui/SettingsScreen/Password'

type ScreenType = {
    navigation?: any
}

const SettingsScreen: FC<ScreenType> = ({ navigation }) => {
    const { user } = useUserContext()
    if (!user) return <></>
    return (
        <SafeArea>
            <ImageBackground
                style={{ flex: 1 }}
                source={{ uri: "https://picsum.photos/1024" }}
                resizeMode="cover">
                <Profile user={user} />
                <Password />
            </ImageBackground>
        </SafeArea >
    )
}

export default SettingsScreen

