import React from 'react'

export interface UserContextType {
    user: {
        _id: string
        username: string
        email: string
    }|null,
    setUser: (user:any) => void
}

const defaultState = {
    user: null,
    setUser: () => {}
}

const UserContext = React.createContext<UserContextType>(defaultState)
const useUserContext = () => React.useContext(UserContext);

export { UserContext, useUserContext }