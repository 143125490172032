import React, { useEffect, FC, useState } from 'react'
import { gql, useMutation, useSubscription, useQuery, useLazyQuery } from "@apollo/client";
import { View, Text, TouchableOpacity, StyleSheet, ImageBackground, Dimensions } from "react-native";
import SafeArea from "../../shared/SafeArea";
import { Surface, TextInput, Title, Button } from 'react-native-paper';

type ScreenType = {
    navigation?: any
}

const ForgotPassScreen: FC<ScreenType> = ({ navigation }) => {
    const [username, setUsername] = React.useState('')
    const [email, setEmail] = React.useState('')
    const [password, setPassword] = React.useState('')

    return (
        <SafeArea>
            <ImageBackground
                source={{ uri: "https://picsum.photos/1024" }}
                resizeMode="cover"
                style={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}
            >
                <Surface style={{ minWidth: 400, maxWidth: 700, minHeight: 200, borderRadius: 8, padding: 20 }}>
                    <View style={{ display: "flex", flexDirection: "column" }}>
                        <Title style={{ marginTop: 15, color: "#727272" }}>
                            Greetings alienkeeper!
                        </Title>
                        <TextInput
                            label="Username or email"
                            style={{ marginTop: 0 }}
                            mode={"outlined"}
                            value={username}
                            multiline={false}
                            numberOfLines={1}
                            onChangeText={(text) => setUsername(text)}
                        />
                    </View>
                    <Button
                        mode="contained"
                        style={{ marginTop: 15, marginBottom: 0, marginLeft: 'auto', marginRight: 'auto', minWidth: 150, maxWidth: 120 }}
                    // onPress={() => onPressSave()}
                    >Continue</Button>
                    <View style={{ flex: 1, flexDirection: 'row', marginTop: 15 }}>
                        <TouchableOpacity onPress={() => navigation.navigate('SignInScreen')} style={{ marginTop: 10, flex: 1 }}>
                            <Text style={{ color: '#767676' }}>Try login again</Text>
                        </TouchableOpacity>
                        <TouchableOpacity onPress={() => navigation.navigate('SignInScreen')} style={{ marginTop: 10, flex: 1 }}>
                            <Text style={{ color: '#767676', textAlign: 'right' }}>Sign up here, it's free.</Text>
                        </TouchableOpacity>
                    </View>
                </Surface>
            </ImageBackground>
        </SafeArea >
    )
}

export default ForgotPassScreen
