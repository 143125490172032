import Constants from "expo-constants"
import { split, HttpLink, ApolloClient, InMemoryCache } from '@apollo/client'
import { sseLink } from './sseLink'
import { getMainDefinition } from '@apollo/client/utilities'

const apiEndopoint: string = Constants.manifest!.extra!.apiEndpoint

const httpLink = new HttpLink({
    uri: apiEndopoint,
    credentials: 'include'
})

const splitLink = split(
    ({ query }) => {
        const definition = getMainDefinition(query);
        return (
            definition.kind === 'OperationDefinition' &&
            definition.operation === 'subscription'
        );
    },
    sseLink,
    httpLink
)

export const client = new ApolloClient({
    link: splitLink,
    cache: new InMemoryCache({
        typePolicies: {
            Alien: {
                keyFields: ['_id']
            }
        }
    }),
})