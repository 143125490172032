import {DefaultTheme} from 'react-native-paper';

/**
 A theme usually contains the following properties:

 dark (boolean): whether this is a dark theme or light theme.
 mode ('adaptive' | 'exact'): color mode for dark theme (See Dark Theme).
 roundness (number): roundness of common elements, such as buttons.
 colors (object): various colors used throughout different elements.
 primary - primary color for your app, usually your brand color.
 accent - secondary color for your app which complements the primary color.
 background - background color for pages, such as lists.
 surface - background color for elements containing content, such as cards.
 text - text color for content.
 disabled - color for disabled elements.
 placeholder - color for placeholder text, such as input placeholder.
 backdrop - color for backdrops of various components such as modals.
 onSurface - background color for snackbars
 notification - background color for badges
 fonts (object): various fonts used throughout different elements.
 regular
 medium
 light
 thin
 animation (object)
 scale - scale for all animations

 const DefaultTheme: Theme = {
    dark: false,
    roundness: 4,
    colors: {
        primary: '#6200ee',
        accent: '#03dac4',
        background: '#f6f6f6',
        surface: white,
        error: '#B00020',
        text: black,
        onSurface: '#000000',
        disabled: color(black).alpha(0.26).rgb().string(),
        placeholder: color(black).alpha(0.54).rgb().string(),
        backdrop: color(black).alpha(0.5).rgb().string(),
        notification: pinkA400,
    },
    fonts: configureFonts(),
    animation: {
        scale: 1.0,
    },
};
 **/

export const theme = {
    ...DefaultTheme,
    colors: {
        ...DefaultTheme.colors,
        primary: '#600EE6',
        accent: '#414757',
        error: '#f13a59',
        background: '#e3e3e3',
        drawer: '#232323'
    },
    fontSizes: {
        caption: '12px',
        button: '14px',
        body: '16px',
        title: '20px',
        h5: '12px',
        h4: '16px',
        h3: '20px',
        h2: '30px',
        h1: '40px',
    },
    fontWeights: {
        regular: 400,
        medium: 500,
        bold: 700,
    }
};
