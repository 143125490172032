import React, { useEffect, FC, useState } from 'react'
import { gql, useMutation, useSubscription, useQuery, useLazyQuery } from "@apollo/client";
import { View, Text, TouchableOpacity, StyleSheet, ImageBackground, Dimensions } from "react-native"
import { ids, styles } from "@ui/MyAliensScreen/SpaceSettings/styles"
import MaterialCommunityIcons from "@expo/vector-icons/MaterialCommunityIcons"
import { FlatList } from 'react-native'
import { Card, Badge, List, TextInput, Button, Title, Avatar } from "react-native-paper"
import { CreateFeeder } from './CreateFeeder'
import { DeleteFeeder } from './DeleteFeeder'

type ScreenType = {
    navigation?: any
    space: SpaceType
}

type SpaceType = {
    _id: string,
    name: string,
    accesses: AccessType[]
    feeders: FeederType[]
}

type AccessType = {
    userId: string,
    type: string,
}

type FeederType = {
    _id: string,
    name: string,
}

export const FeederSettings: FC<ScreenType> = ({ navigation, space }) => {
    const renderFeeder = ({ item }: any) => {
        const feeder = item
        return (
            <View style={{ flex: 1, flexDirection: 'row', justifyContent: 'space-between', borderTop: '1px dotted #ccc', borderBottom: '1px dotted #ccc', paddingTop: 8, paddingBottom: 8 }}>
                <View style={{ flexDirection: 'row' }}>
                    <Text style={{ fontSize: 18, marginTop: 3, marginLeft: 5 }}>{feeder.name}</Text>
                </View>
                <View style={{ flexDirection: 'row' }}>
                    <DeleteFeeder spaceId={space._id} feeder={feeder} anchor={
                        <Button mode="contained" style={{ width: 96 }}>REMOVE</Button>
                    } />
                </View>
            </View>
        )
    }
    return (
        <Card style={styles.settingsCard} dataSet={{ media: ids.settingsCard }}>
            <View style={{ padding: 16 }}>
                <Title style={{ fontSize: 22 }}>
                    Food tracking
                </Title>
                <View style={{ flex: 1, flexDirection: 'column', justifyContent: 'space-between' }}>
                    <Text style={{ flex: 1, color: 'rgba(0, 0, 0, 0.54);', fontSize: 18 }}>What you feed to animals in this space</Text>
                    <CreateFeeder spaceId={space._id} anchor={
                        <Button mode="contained" style={{ marginTop: 5, marginBottom: 5, maxWidth: 200 }}>
                            <MaterialCommunityIcons
                                style={{ color: "#ffffff", marginRight: 4 }}
                                name="food-drumstick"
                                size={18}
                            /> NEW FOOD</Button>
                    } />
                    <FlatList
                        style={{ padding: 0, marginTop: 25 }}
                        data={space.feeders}
                        renderItem={renderFeeder}
                        showsHorizontalScrollIndicator={false}
                        showsVerticalScrollIndicator={false}
                        keyExtractor={item => item._id}
                    />
                </View>
            </View>
        </Card >
    )
}