import * as React from "react"
import { View, Text } from "react-native";
import { Button, Menu, Divider, Provider, TextInput, Checkbox } from "react-native-paper"
import MaterialCommunityIcons from "@expo/vector-icons/MaterialCommunityIcons"
import { gql, useMutation, useSubscription, useQuery } from "@apollo/client";
import { TouchableOpacity } from "react-native-gesture-handler"
import moment from 'moment'

const DELETE_SPACE_MUTATAION = gql`
  mutation Mutation($spaceId: String) {
    deleteSpace(spaceId: $spaceId)
  }
`;

type DeleteSpaceType = {
    anchor: object
    spaceId: string
    setShowSettings: (val:boolean) => {}
    changeActiveSpace: (space: any|null) => {}
}

export const DeleteSpace = ({ anchor, spaceId, setShowSettings, changeActiveSpace }: DeleteSpaceType) => {
    const [visible, setVisible] = React.useState(false)
    const [deleteSpace, { error }] = useMutation(DELETE_SPACE_MUTATAION);
    const openMenu = () => setVisible(true)
    const closeMenu = () => setVisible(false)

    const onPressSave = async () => {
        await deleteSpace({
            variables: {
                spaceId
            }
        }).then(
            // ()=> changeActiveSpace(null)
            () => {
                // changeActiveSpace()
                // closeMenu()
                changeActiveSpace(null)
                setShowSettings(false)
            }
        )
    }
    return (
        <View>
            <Menu
                visible={visible}
                onDismiss={closeMenu}
                anchor={<TouchableOpacity onPress={openMenu}>{anchor}</TouchableOpacity>}
            >
                <View style={{ padding: 10, maxWidth: 300 }}>
                    <Text>This action will permanently remove the space and all the aliens within it!</Text>
                    <Button
                        mode="contained"
                        style={{ marginTop: 15, maxWidth: 120 }}
                        onPress={() => onPressSave()}
                    >
                        Confirm
                    </Button>
                </View>
            </Menu>
        </View>
    )
}
