import React from "react"
import { Text, View } from "react-native"
import { ids, styles } from "./styles"
import { Card, Badge, List } from "react-native-paper"
import MaterialCommunityIcons from "@expo/vector-icons/MaterialCommunityIcons"
import moment from 'moment'

const typeSnakeImg = require('../../../../assets/alien-type-snake.jpg')
const typeSpiderImg = require('../../../../assets/alien-type-spider.jpg')
const typeMantisImg = require('../../../../assets/alien-type-mantis.jpg')
const typeLizardImg = require('../../../../assets/alien-type-lizard.jpg')
const typeOtherImg = require('../../../../assets/alien-type-other.jpg')

type FeedingType = {
  _id: string
  type: string
  date: string
  name: string
}

type MoltType = {
  _id: string
  type: string
  date: string
  num: number
}

type NoteType = {
  _id: string
  type: string
  date: string
  text: string
}

type DndType = {
  date: string
  text: string
}

type MyAlienCardType = {
  name: string
  sex: string
  species: string
  feedings: FeedingType[]
  molts: MoltType[]
  notes: NoteType[]
  dnd: DndType[]
  type: string | undefined
  hunger: number | undefined
  activities: object[]
}

const ACTIVITY_DISPLAY_ITEMS = 4

export const MyAlienCard = ({ name, sex, species, feedings, molts, notes, dnd, type, hunger, activities }: MyAlienCardType): JSX.Element => {
  const genderMale = (
    <MaterialCommunityIcons
      name="gender-male"
      style={styles.alienGender}
      size={16}
      color="lightblue"
    />
  )
  const genderFemale = (
    <MaterialCommunityIcons
      name="gender-female"
      style={styles.alienGender}
      size={16}
      color="pink"
    />
  )
  let alienGender: JSX.Element | undefined = undefined;
  if (sex === "male") alienGender = genderMale;
  if (sex === "female") alienGender = genderFemale;

  const hasStickyNotes = (notes: NoteType[]): boolean => {
    if (!notes) return false
    for (let i = 0; i < notes.length; i++) {
      if (notes[i].type === 'sticky') return true
    }
    return false
  }

  const getCurrentMoltString = (molts: MoltType[]): String => {
    if (!molts || molts.length === 0) return ''
    let lastMolt = molts[0]
    let moltString = 'L' + lastMolt.num
    if (lastMolt.type === 'adult') {
      moltString += ' (adult)'
    }
    return moltString
  }

  const genderSpecies = () => {
    // const moltNum = getCurrentMoltNum(molts)
    return (
      <View style={styles.genderSpecies}>
        {/* <MaterialCommunityIcons name="gender-female" size={16} color="lightblue" /> */}
        {alienGender && alienGender}
        <Text style={styles.alienSpecies}>{getCurrentMoltString(molts)} {species}</Text>
      </View>
    );
  }
  const renderNoFeedings = () => {
    return (
      <List.Section style={{ marginBottom: 0 }}>
        <List.Subheader
          style={{
            paddingTop: 5,
            paddingBottom: 5,
            backgroundColor: "#B8E3E9",
          }}
        >
          <MaterialCommunityIcons
            name="information-outline"
            style={styles.alienLastFed}
            size={16}
            color="green"
          />
          Click "Feed" to start tracking feeding.
        </List.Subheader>
      </List.Section>
    )
  }

  const renderLastFed = (feedings: FeedingType[], hunger: number | null = null) => {
    let lastFed = null;
    if (!feedings) return (renderNoFeedings())
    for (var i = 0; i < feedings.length; i++) {
      if (feedings[i].type == 'ok') {
        lastFed = { date: feedings[i].date, name: feedings[i].name }
        break;
      }
    }
    if (!lastFed) return (renderNoFeedings())
    let barColor
    if (hunger === null) {
      barColor = '#cccccc'
    } else if (hunger > 75 && hunger <= 100) {
      barColor = '#f1dd9b'
    } else if (hunger > 100) {
      barColor = '#feb9b9'
    } else {
      barColor = '#c2e9b8'
    }
    return (
      <List.Section style={{ marginBottom: 0 }}>
        <List.Subheader
          style={{
            paddingTop: 5,
            paddingBottom: 5,
            backgroundColor: barColor,
            // backgroundColor: "#c2e9b8",
          }}
        >
          <MaterialCommunityIcons
            name="bug"
            style={styles.alienLastFed}
            size={16}
            color="green"
          />
          Fed {moment(lastFed.date).fromNow()} ({lastFed.name})
        </List.Subheader>
      </List.Section>
    )
  }

  const truncateLongText = (text: string, size: number) => {
    return text.length > size ? text.slice(0, size - 1) + '...' : text
  }

  const renderLatestActivity = (activities: any) => {
    if (!activities) return (<></>)
    let latestActivity = []
    const limit = activities.length < ACTIVITY_DISPLAY_ITEMS ? activities.length : ACTIVITY_DISPLAY_ITEMS
    // const limit = feedings.length
    for (let i = 0; i < limit; i++) {
      const listItemClass = (i + 1) === limit ? styles.activityListItemLast : styles.activityListItem
      const activity = activities[i]
      if (activity.__typename === 'Feeding') {
        const badgeColorStyle = activity.type === 'ok' ? styles.badgeGreen : styles.badgeOrange
        const iconColorStyle = activity.type === 'ok' ? styles.iconGreen : styles.iconOrange
        latestActivity.push(
          <List.Item
            key={i}
            title={activity.name}
            style={listItemClass}
            // description={moment(activity.date).format('MMMM Do YYYY')}
            description={moment(activity.date).fromNow()}
            descriptionStyle={{color: iconColorStyle.color }}
            descriptionNumberOfLines={1}
            titleNumberOfLines={0}
            titleStyle={{ marginBottom: 3 }}
            left={(props) => (
              <View style={{ alignItems: 'center', paddingTop: 12, maxWidth: 70 }}>
                <MaterialCommunityIcons
                  style={{ margin: 0, backgroundColor: iconColorStyle.color, borderRadius: 50, color: 'white', padding: 5 }}
                  name="bug"
                  size={16}
                />
              </View>
            )}
          />
        )
      } else if (activity.__typename === 'Molt') {
        let moltString = 'Molted from L' + (activity.num - 1) + ' to L' + activity.num
        if (activity.type === 'adult') {
          moltString += ' (adult)'
        }
        latestActivity.push(
          <List.Item
            key={i}
            title={moltString}
            style={listItemClass}
            // description={moment(activity.date).format('MMMM Do YYYY')}
            description={moment(activity.date).fromNow()}
            descriptionStyle={{color: 'purple' }}
            descriptionNumberOfLines={1}
            titleNumberOfLines={0}
            left={(props) => (
              <View style={{ alignItems: 'center', paddingTop: 12, maxWidth: 70 }}>
                <MaterialCommunityIcons
                  style={{ margin: 0, backgroundColor: 'purple', borderRadius: 50, color: 'white', padding: 5 }}
                  name="autorenew"
                  size={16}
                />
              </View>
            )}
          />
        )
      } else if (activity.__typename === 'Note') {
        latestActivity.push(
          <List.Item
            key={i}
            title={truncateLongText(activity.text, 64)}
            style={listItemClass}
            // description={moment(activity.date).format('MMMM Do YYYY')}
            descriptionNumberOfLines={1}
            description={moment(activity.date).fromNow()}
            descriptionStyle={{color: styles.iconBlue.color }}
            titleNumberOfLines={0}
            titleStyle={{ marginBottom: 3 }}
            left={(props) => (
              <View style={{ alignItems: 'center', paddingTop: 12, maxWidth: 70 }}>
                <MaterialCommunityIcons
                  style={{ margin: 0, backgroundColor: styles.iconBlue.color, borderRadius: 50, color: 'white', padding: 5 }}
                  name="comment-text-outline"
                  size={16}
                />
              </View>
            )}
          />
        )
      }
    }
    return latestActivity
  }

  const renderLastFeedings = (feedings: FeedingType[]) => {
    if (!feedings) return (<></>)
    let lastFeedings = []
    const limit = feedings.length < 4 ? feedings.length : 4
    // const limit = feedings.length
    for (let i = 0; i < limit; i++) {
      const badgeColor = feedings[i].type === 'ok' ? 'green' : 'orange'
      const listItemClass = (i + 1) == limit ? styles.alienLastFedItemNoborder : styles.alienLastFedItem
      lastFeedings.push(
        (
          <List.Item
            key={i}
            style={listItemClass}
            // style={styles.alienLastFedItem}
            title={feedings[i].name}
            disabled={true}
            left={(props) => (
              <Badge
                style={{
                  marginBottom: 5,
                  marginLeft: 4,
                  backgroundColor: badgeColor,
                  color: "white",
                  minWidth: 65,
                }}
              >
                {moment(feedings[i].date).fromNow()}
              </Badge>
            )}
          />
        )
      )
    }
    return (
      <List.Section style={{ marginTop: 0, marginBottom: 0 }}>
        {lastFeedings}
      </List.Section>
    )
  }
  const isWithStickyNotes = hasStickyNotes(notes)
  const dndIconRightPosition = isWithStickyNotes ? 80 : 46
  let alienImg
  if (typeof type === 'undefined' || type === null || !type) {
    alienImg = typeOtherImg
  } else if (type === 'snake') {
    alienImg = typeSnakeImg
  } else if (type === 'spider') {
    alienImg = typeSpiderImg
  } else if (type === 'mantis') {
    alienImg = typeMantisImg
  } else if (type === 'lizard') {
    alienImg = typeLizardImg
  } else if (type === 'lizard') {
    alienImg = typeLizardImg
  } else {
    alienImg = typeOtherImg
  }
  return (
    <Card style={styles.myAlienCard} dataSet={{ media: ids.myAlienCard }}>
      <View style={styles.mainRow}>
        <Card.Cover
          style={styles.alienAvatar}
          source={{ uri: alienImg }}
        // source={{ uri: "https://picsum.photos/700" }}
        />
        <Card.Title
          style={styles.alienName}
          // title={type}
          title={name}
          subtitle={genderSpecies()}
        />
        {isWithStickyNotes && <MaterialCommunityIcons
          style={{ color: "orange", position: 'absolute', top: 10, right: 50 }}
          name="alert"
          size={20}
        />}
        {dnd && <MaterialCommunityIcons
          style={{ color: "red", position: 'absolute', top: 10, right: dndIconRightPosition }}
          name="hand-back-left-off"
          size={20}
        />}
      </View>
      <View>
        {/* {(!feedings) && renderNoFeedings()} */}
        {renderLastFed(feedings, hunger)}
        {activities && renderLatestActivity(activities)}
        {/* {feedings && renderLastFeedings(feedings)} */}
      </View>
    </Card>
  )
}