import React, { FC } from 'react'
import { View, Text } from "react-native"
import { ids, styles } from "@ui/MyAliensScreen/SpaceSettings/styles"
import MaterialCommunityIcons from "@expo/vector-icons/MaterialCommunityIcons"
import { FlatList } from 'react-native'
import { Card, Button, Title, Avatar } from "react-native-paper"
import { useUserContext } from '@ui/shared/UserContext'
import { InviteMember } from '@ui/MyAliensScreen/SpaceSettings/InviteMember'
import { RemoveMember } from '@ui/MyAliensScreen/SpaceSettings/RemoveMember'
import { PromoteMember } from '@ui/MyAliensScreen/SpaceSettings/PromoteMember'
import { DemoteMember } from '@ui/MyAliensScreen/SpaceSettings/DemoteMember'
import { RenameSpace } from '@ui/MyAliensScreen/SpaceSettings/RenameSpace'
import { OtherSettings } from '@ui/MyAliensScreen/SpaceSettings/OtherSettings'
import { FeederSettings } from '@ui/MyAliensScreen/SpaceSettings/FeederSettings'

type ScreenType = {
    navigation?: any
    space: SpaceType
    setShowSettings: (val: any) => void
    changeActiveSpace: (space: SpaceType | null) => void
}

type SpaceType = {
    _id: string,
    name: string,
    created: string,
    accesses: AccessType[]
    feeders: FeederType[]
}

type AccessType = {
    userId: string,
    type: string,
}

type FeederType = {
    _id: string
    name: string
}

const SpaceSettings: FC<ScreenType> = ({ navigation, space, setShowSettings, changeActiveSpace }) => {
    const { user: currentUser } = useUserContext()
    const isAdminOfSpace = (space: SpaceType): boolean => {
        for (let i = 0; i < space.accesses.length; i++) {
            if (space.accesses[i].userId === currentUser?._id && space.accesses[i].type === 'admin') return true
        }
        return false
    }
    const isAdmin = isAdminOfSpace(space)
    const renderMember = ({ item }: any) => {
        const access = item
        return (
            <View style={{ flex: 1, flexDirection: 'row', justifyContent: 'space-between', borderTop: '1px dotted #ccc', borderBottom: '1px dotted #ccc', paddingTop: 8, paddingBottom: 8 }}>
                <View style={{ flexDirection: 'row' }}>
                    <Avatar.Text style={styles.memberAvatar} size={36} label={access.username.substring(0, 2)} />
                    <Text style={{ fontSize: 18, marginTop: 3, marginLeft: 5 }}>@{access.username}</Text>
                </View>
                <View style={{ flexDirection: 'row' }}>
                    {access.type === 'admin' && isAdmin
                        && <DemoteMember userId={access.userId} spaceId={space._id} anchor={
                            <Button mode="contained" disabled={true} style={{ width: 96 }}>Admin</Button>
                        } />
                    }
                    {access.type === 'admin' && !isAdmin && <Button mode="contained" disabled={true} style={{ width: 96 }}>Admin</Button>}
                    {access.type === 'member' && isAdmin
                        && <PromoteMember userId={access.userId} spaceId={space._id} anchor={
                            <Button mode="contained" disabled={false} style={{ width: 96 }}>Admin</Button>
                        } />
                    }
                    {(isAdmin || currentUser?._id === access.userId)
                        && <RemoveMember userId={access.userId} spaceId={space._id} anchor={
                            <Button mode="contained" disabled={false} style={{ width: 96, marginLeft: 5 }}>Remove</Button>
                        } />
                    }

                </View>
            </View>
        )
    }
    if(!space) return(<Text>Loading...</Text>)
    return (
        <View style={styles.settingsContainer}>
            <Card style={styles.settingsCard} dataSet={{ media: ids.settingsCard }}>
                <Card.Title title={'Space settings'}
                // subtitle={'This action is'}
                />
                <Card.Content style={{ padding: 16, paddingTop: 0 }}>
                    <RenameSpace spaceId={space._id} spaceName={space.name} />
                </Card.Content>
            </Card>
            <Card style={styles.settingsCard} dataSet={{ media: ids.settingsCard }}>
                <Card.Title title={'Space members'} />
                <View style={{ padding: 16, paddingTop: 0 }}>
                    <View style={{ flex: 1, flexDirection: 'column', justifyContent: 'space-between' }}>
                        <Text style={{ flex: 1, color: 'rgba(0, 0, 0, 0.54);', fontSize: 18 }}>Invite existing alienkeeper users:</Text>
                        <InviteMember spaceId={space._id} anchor={
                            <Button mode="contained" style={{ marginTop: 5, marginBottom: 5, maxWidth: 200 }}>
                                <MaterialCommunityIcons
                                    style={{ color: "#ffffff", marginRight: 4 }}
                                    name="account-multiple-plus"
                                    size={18}
                                /> Invite member</Button>
                        } />
                        {/* <Text style={{ flex: 1, color: 'rgba(0, 0, 0, 0.54);', fontSize: 18, marginTop: 10 }}>Or invite new users to alienkeeper:</Text>
                    <Button mode="contained" style={{ marginTop: 5, marginBottom: 5, maxWidth: 200 }}>
                        <MaterialCommunityIcons
                            style={{ color: "#ffffff", marginRight: 4 }}
                            name="link"
                            size={18}
                        /> Invite new user</Button> */}
                        <Title style={{ marginTop: 25, marginBottom: 10 }}>Space members</Title>
                        <FlatList
                            style={{ padding: 0 }}
                            data={space.accesses}
                            renderItem={renderMember}
                            showsHorizontalScrollIndicator={false}
                            showsVerticalScrollIndicator={false}
                            keyExtractor={item => item.userId}
                        />
                    </View>
                    <Text style={{ marginTop: 15, color: 'rgba(0, 0, 0, 0.54);' }}><Text style={{ color: 'rgb(96, 14, 230)' }}>Click here</Text> for more info about user permissions.</Text>
                </View>
            </Card >
            <FeederSettings space={space} />
            <OtherSettings space={space} setShowSettings={setShowSettings} changeActiveSpace={changeActiveSpace} />
        </View>
    )
}

export default SpaceSettings